import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import toast from 'react-hot-toast';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Breadcrumbs,
} from '@mui/material';
// components
import axios from "axios";
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections

import { MaterialRequisitionListToleBar, WorkOrderHead, WorkOrderToolBar } from '../sections/@dashboard/workOrder';
import RandomQCDialogBox from '../components/random-qc-dialog-box';
import { APICONFIG } from '../config/apiConfig';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sl_no', label: 'Sl.No', alignRight: false },
  { id: 'ProjectID', label: 'Project ID', alignRight: false },
  { id: 'WorkOrderId', label: 'WO', alignRight: false },
  { id: 'QcId', label: 'Document No', alignRight: false },
  { id: 'CreatedBy', label: 'Inititator', alignRight: false },
  { id: 'createdOn', label: 'QC On', alignRight: false },
  { id: '' },
];

const USERLIST = [
    {
      TimeSheetOn: '8/10/2023',
      signType: 'Sign A 1.2',
      WO: 'WO-1',
      projectID: 'BRI UAE-J1-08-23',
      DocumentNo: 'MRDS001091',
      Department: 'Vinyl',
    },
    {
      TimeSheetOn: '8/11/2023',
      signType: 'Sign A',
      WO: 'WO-2',
      projectID: 'BRI UAE-J1-09-23',
      DocumentNo: 'MRDS001092',
      Department: 'Vinyl',
    },
    {
      TimeSheetOn: '8/11/2023',
      signType: 'Sign C 8.2',
      WO: 'WO-3',
      projectID: 'BRI UAE-J1-08-23',
      DocumentNo: 'MRDS001091',
      Department: 'Electrical',
    },
    {
      TimeSheetOn: '8/12/2023',
      signType: 'Sign B 2.1',
      WO: 'WO-4',
      projectID: 'BRI UAE-J1-10-23',
      DocumentNo: 'MRDS001093',
      Department: 'Acrylic',
    },
    {
      TimeSheetOn: '8/13/2023',
      signType: 'Sign D 4.0',
      WO: 'WO-5',
      projectID: 'BRI UAE-J1-11-23',
      DocumentNo: 'MRDS001092',
      Department: 'Electrical',
    },
    {
      TimeSheetOn: '8/14/2023',
      signType: 'Sign A 1.2',
      WO: 'WO-6',
      projectID: 'BRI UAE-J1-12-23',
      DocumentNo: 'MRDS001091',
      Department: 'Vinyl',
    },
    {
      TimeSheetOn: '8/15/2023',
      signType: 'Sign C 8.2',
      WO: 'WO-7',
      projectID: 'BRI UAE-J1-13-23',
      DocumentNo: 'MRDS001093',
      Department: 'Acrylic',
    },
    {
      TimeSheetOn: '8/16/2023',
      signType: 'Sign A 1.2',
      WO: 'WO-8',
      projectID: 'BRI UAE-J1-14-23',
      DocumentNo: 'MRDS001092',
      Department: 'Vinyl',
    },
    {
      TimeSheetOn: '8/17/2023',
      signType: 'Sign B 2.1',
      WO: 'WO-9',
      projectID: 'BRI UAE-J1-15-23',
      DocumentNo: 'MRDS001091',
      Department: 'Acrylic',
    },
    {
      TimeSheetOn: '8/18/2023',
      signType: 'Sign A',
      WO: 'WO-10',
      projectID: 'BRI UAE-J1-16-23',
      DocumentNo: 'MRDS001093',
      Department: 'Electrical',
    },
  ];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  try{
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let filteredArray = stabilizedThis.map((el) => el[0]);

  if (query) {
    query = query.toLowerCase();
    filteredArray = filteredArray.filter((_user) => 
      _user.ProjectID.toLowerCase().indexOf(query.toLowerCase()) !== -1
      || _user.WorkOrderId.toLowerCase().indexOf(query.toLowerCase()) !== -1
      || _user.QcId.toLowerCase().indexOf(query.toLowerCase()) !== -1
      || _user.CreatedBy.toLowerCase().indexOf(query.toLowerCase()) !== -1
      || _user.createdOn.toLowerCase().indexOf(query.toLowerCase()) !== -1
   
    );
  }

  return filteredArray;
}catch(e)
{
  console.log(e);
}
return[];
}

export default function RandomQcListPage() {
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [randomQcList,setRandomQcList]=useState([]);
  const [apiBaseUrl, setApiBaseUrl] = useState(`${APICONFIG.HOST}/${APICONFIG.API_PREFIX}/${APICONFIG.QC.ROOT}`);
 
  const fetchWoList = async () => {
    try {

      const res = await axios.get(`${apiBaseUrl}/${APICONFIG.QC.GET_RANDOM_QC}/${10}`, {
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${authData.access_token}`
        }
      });
      console.log(res.data.data,' random qc list');
      setRandomQcList(res.data.data);
   
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchWoList();
  }, []);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - randomQcList.length) : 0;

  const filteredUsers = applySortFilter(randomQcList, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;  

  const [mrdViewListDilogBoxOpen, setMrdDilogBoxOpen] = useState(false);
  const [dataPopArray, setDataPopArray] = useState({QcId:'',WorkOrderId:'',Remarks:'',ProductImg:'',RandomQcId:0,DepartmentId:'',QcParams:[]});

  const mrdHandleOpenDialog = (data) => {
    console.log(dataPopArray,' dataPopArray');
    setDataPopArray(data);
    console.log(data,' data');
    setMrdDilogBoxOpen(true);
  };

  const mrdHandleCloseDialog = () => {
    setMrdDilogBoxOpen(false);
  };

  const linknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
    color: 'rgb(33, 43, 54)',
  };

  const actlinknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
  };

  return (
    <>
      <Helmet>
      <title> Random QC List | BRI Middleware </title>
      </Helmet>

      <Container maxWidth="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
            Random QC List
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <Iconify icon="ic:baseline-circle" width="6px" borderRadius="50%" color="rgb(145, 158, 171)" />
              }
            >
              {/* <Link style={linknav} to="/dashboard">
                Dashboard
              </Link> */}
              <Link style={linknav} to="/randomQcList">
              Random QC List
              </Link>
              {/* <Typography style={actlinknav} color="text.secondary">
                List
              </Typography> */}
            </Breadcrumbs>
          </div>
          <div>
            {/* <Button to="/createUser" color='primary' variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} component={Link}>
              New User
            </Button> */}
          </div>
        </Stack>

        <Card>

          <MaterialRequisitionListToleBar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <WorkOrderHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={randomQcList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, ProjectID, WorkOrderId, QcId, CreatedBy, createdOn } = row;
                    const serialNumber = page * rowsPerPage + index + 1;
                    return (
                      <TableRow hover key={serialNumber} tabIndex={-1}>
                        <TableCell padding="checkbox">
                          <Typography variant="subtitle2" noWrap paddingX={2}>
                            {serialNumber}
                          </Typography>
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none" style={{ Width: '10px !important' }}>
                          <Typography variant="subtitle2">{ProjectID}</Typography>
                        </TableCell>

                        <TableCell align="left">{WorkOrderId}</TableCell>

                        <TableCell align="left">{QcId}</TableCell>

                        <TableCell align="left">{CreatedBy}</TableCell>

                        <TableCell>{createdOn}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="primary" onClick={() => mrdHandleOpenDialog(row)}>
                            <Iconify icon={'eva:external-link-outline'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={filteredUsers.length <= 5 ? [0] : [5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
       <RandomQCDialogBox 
        open={mrdViewListDilogBoxOpen}
        onClose={mrdHandleCloseDialog}
        dataPop={dataPopArray}
       />                   
    </>
  );
}
