// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard',
  //   icon: icon('ic_analytics'),
  //   area: 'default'
  // },
  {
    title: 'Final QC List',
    path: '/finalQcList',
    icon: icon('ic_analytics'),
    area: 'Transcations'
  },
  {
    title: 'Random QC List',
    path: '/randomQcList',
    icon: icon('ic_analytics'),
    area: 'Transcations'
  },
  {
    title: 'Random QC',
    path: '/randomWoQc',
    icon: icon('ic_wo_list'),
    area: 'Transcations'
  },
  {
    title: 'Final QC',
    path: '/woFinalQc',
    icon: icon('ic_wo_list'),
    area: 'Transcations'
  },
  // {
  //   title: 'WO Operation Planning',
  //   path: '/woOperationPlanning',
  //   icon: icon('ic_wo_op'),
  //   area: 'Transcations'
  // },
  // {
  //   title: 'Operation Activites',
  //   path: '/operationActivites',
  //   icon: icon('ic_oa'),
  //   area: 'Transcations'
  // },
  // {
  //   title: 'Material Requisition',
  //   path: '/materialRequisition',
  //   icon: icon('ic_mr'),
  //   area: 'Transcations'
  // },

 
];

export default navConfig;
