import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText, Divider, Typography } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {

  const tranfilteredData = data.filter(item => item.area === 'Transcations');
  const setupfilteredData = data.filter(item => item.area === 'Setup');
  const autofilteredData = data.filter(item => item.area === 'Automation');
  const repofilteredData = data.filter(item => item.area === 'Reports');

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
           (item.area === 'default') && (<NavItem key={item.title} item={item} />)
        ))}
      </List>

      {tranfilteredData.length>0 ? <Typography variant="h4" sx={{ mb: '4px', p:'16px 8px 0px 18px', textTransform: 'uppercase', fontSize: '11px !important' }}>Transcations</Typography>
      :''}
      {tranfilteredData.length>0 ?
        <List disablePadding sx={{ p: 1 }}>
          {data.map((item) => (
            (item.area === 'Transcations') && (<NavItem key={item.title} item={item} />)
          ))}
        </List>      
      :''}
      {setupfilteredData.length>0 ? <Typography variant="h4" sx={{ mb: '4px', p:'16px 8px 0px 18px', textTransform: 'uppercase', fontSize: '11px !important' }}>Setup</Typography>
      :''}
      {setupfilteredData.length>0 ?
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
           (item.area === 'Setup') && (<NavItem key={item.title} item={item} />)
        ))}
      </List>   
      :''}
      {autofilteredData.length>0 ? <Typography variant="h4" sx={{ mb: '4px', p:'16px 8px 0px 18px', textTransform: 'uppercase', fontSize: '11px !important' }}>Automation</Typography>
      :''}
      {autofilteredData.length>0 ?
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
           (item.area === 'Automation') && (<NavItem key={item.title} item={item} />)
        ))}
      </List>   
      :''}
      {repofilteredData.length>0 ? <Typography variant="h4" sx={{ mb: '4px', p:'16px 8px 0px 18px', textTransform: 'uppercase', fontSize: '11px !important' }}>Reports</Typography>
      :''}
      {repofilteredData.length>0 ? 
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
           (item.area === 'Reports') && (<NavItem key={item.title} item={item} />)
        ))}
      </List>   
      :''}
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
