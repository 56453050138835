import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { sentenceCase } from 'change-case';
import {
  Card,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  CardContent,
  Grid,
  Button,
  TextField,
  Autocomplete,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Paper,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Label from '../components/label';
import Iconify from '../components/iconify';
import StatusLabel from '../components/status';


const subsidiaryList = [
  { label: 'BRI UAE', value: 'BRI UAE' },
  { label: 'PRS KSA', value: 'PRS KSA' },
  { label: 'BRI UAQ', value: 'BRI UAQ' },
];

const projectList = [
  { label: 'BRI UAE', value: 'BRI UAE' },
  { label: 'PRS KSA', value: 'PRS KSA' },
  { label: 'BRI UAQ', value: 'BRI UAQ' },
];

const workorderList = [
  { label: 'BRI UAE', value: 'BRI UAE' },
  { label: 'PRS KSA', value: 'PRS KSA' },
  { label: 'BRI UAQ', value: 'BRI UAQ' },
];

const row = {
  workOrderT: 'WO-12453',
  projectID: 'BRI UAE-J1-08-23',
  projectType: 'Projects',
  signType: 'Sign C 8.2',
  salesPerson: 'Nimesh',
  location: 'UAQ/Dubai',
  status: 'In Progress',
  plannedBy: 'Based on Login',
  lastModifiedBy: 'based on User',
};

function createData(operation, ESD, EED, ELH, progress, status) {
  return { operation, ESD, EED, ELH, progress, status };
}

const rows = [
  createData('CNC', '27-02-2023', '02-03-2023', 24, 'Completed', 'Active'),
  createData('Letter Molding', '02-03-2023', '10-03-2023', 37, 'Completed', 'Active'),
  createData('Fabrication', '15-03-2023', '13-03-2023', 24, 'In Progress', 'Active'),
  createData('Polishing', '20-03-2023', '19-03-2023', 67, 'In Progress', 'Active'),
  createData('Sanding', '27-03-2023', '03-04-2023', 49, 'Initiated', 'Active'),
  createData('Painting', '27-03-2023', '03-04-2023', 49, 'Initiated', 'Active'),
  createData('Acrylic', '27-03-2023', '03-04-2023', 49, 'Initiated', 'Active'),
  createData('Electrical', '27-03-2023', '03-04-2023', 49, 'Yet to Plan', 'Inactive'),
  createData('Screen Printing', '27-03-2023', '03-04-2023', 49, 'Yet to Plan', 'Inactive'),
  createData('Vinyl', '27-03-2023', '03-04-2023', 49, 'Yet to Plan', 'Inactive'),
  createData('Packaging', '27-03-2023', '03-04-2023', 49, 'Yet to Plan', 'Inactive'),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 'bold',
  },
}));

export default function WoOperationPlanning() {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const linknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
    color: 'rgb(33, 43, 54)',
  };

  const actlinknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
  };

  return (
    <>
      <Helmet>
        <title> WO Operation Planning | BRI Middleware </title>
      </Helmet>

      <Container maxWidth="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              WO Operation Planning
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <Iconify icon="ic:baseline-circle" width="6px" borderRadius="50%" color="rgb(145, 158, 171)" />
              }
            >
              <Link style={linknav} to="/dashboard">
                Dashboard
              </Link>
              <Link style={linknav} to="/machineryMaster">
                Work Order List
              </Link>
              <Typography style={actlinknav} color="text.secondary">
                WO Operation Planning
              </Typography>
            </Breadcrumbs>
          </div>
          <div>
            {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Machinery
            </Button> */}
          </div>
        </Stack>

        <Grid mb={5}>
          <Card>
            <CardContent>
              <Grid>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Autocomplete
                        size="small"
                        options={subsidiaryList}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Subsidiary"
                            fullWidth
                            {...register('subsidiary', { required: 'Subsidiary is required' })}
                            error={!!errors.subsidiary}
                            helperText={errors.subsidiary?.message}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li key={option} {...props} style={{ fontSize: '0.875rem' }}>
                            {option.label}
                          </li>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        size="small"
                        options={projectList}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Project"
                            fullWidth
                            {...register('project', { required: 'Project is required' })}
                            error={!!errors.project}
                            helperText={errors.project?.message}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li key={option} {...props} style={{ fontSize: '0.875rem' }}>
                            {option.label}
                          </li>
                        )}
                      />
                    </Grid>

                    {/* Work Order Select Box */}
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        size="small"
                        options={workorderList}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Workorder"
                            fullWidth
                            {...register('workorder', { required: 'Workorder is required' })}
                            error={!!errors.workorder}
                            helperText={errors.workorder?.message}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li key={option} {...props} style={{ fontSize: '0.875rem' }}>
                            {option.label}
                          </li>
                        )}
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12} sm={1}>
                      <Button
                        fullWidth
                        style={{ marginTop: '1.5px' }}
                        variant="contained"
                        color="primary"
                        size="medium"
                        type="submit"
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </CardContent>
            <Grid>
              <Divider>
                <Label color="info">WORK ORDER DETAILS</Label>
              </Divider>
                <Grid mt={1}>
                  <TableContainer component={Paper}>
                    <Table sx={{ maxWidth: '100%' }} size="small" aria-label="caption table">
                      <TableBody>
                        <TableRow>
                          <StyledTableCell2 align="left" width={150}>
                            Work Order:
                          </StyledTableCell2>
                          <TableCell align="left" colSpan={1}>
                            {row.workOrderT}
                          </TableCell>
                          <StyledTableCell2 align="left" width={150}>
                            Project Type:
                          </StyledTableCell2>
                          <TableCell align="left" colSpan={2}>
                            {row.projectType}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell2 align="left" width={150}>Sign Type:</StyledTableCell2>
                          <TableCell align="left">{row.signType}</TableCell>
                          <StyledTableCell2 align="left" width={150}>Sales Person:</StyledTableCell2>
                          <TableCell align="left">{row.salesPerson}</TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell2 align="left">Location:</StyledTableCell2>
                          <TableCell align="left">{row.location}</TableCell>
                          <StyledTableCell2 align="left">Status:</StyledTableCell2>
                          <TableCell align="left">
                            <StatusLabel status={row.status} />                            
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell2 align="left">Planned By:</StyledTableCell2>
                          <TableCell align="left">{row.plannedBy}</TableCell>
                          <StyledTableCell2 align="left">Last Modified By:</StyledTableCell2>
                          <TableCell align="left">{row.lastModifiedBy}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container justifyContent="flex-end" alignItems="flex-end" mt={2} gap={2} pr={2} pb={2}>
                    {/* <Grid item>
                      <Button variant="contained" color="success" size="small">
                        Plan Project
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="warning" size="small">
                        Project Hold
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="error" size="small">
                        Project Cancel
                      </Button>
                    </Grid> */}
                  </Grid>
                </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid>
          <Card>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{minWidth:200}}>Operation</StyledTableCell>
                    <StyledTableCell style={{maxWidth:112}} align="center">Estimated Start Date</StyledTableCell>
                    <StyledTableCell style={{maxWidth:112}} align="center">Estimated End Date</StyledTableCell>
                    <StyledTableCell style={{maxWidth:114}} align="center">Estimated Laber Hours</StyledTableCell>
                    <StyledTableCell style={{maxWidth:114}} align="center">Progress</StyledTableCell>
                    <StyledTableCell style={{maxWidth:114}} align="center">Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.operation}
                      </TableCell>
                      <TableCell align="center">{row.ESD}</TableCell>
                      <TableCell align="center">{row.EED}</TableCell>
                      <TableCell align="center">{row.ELH}</TableCell>
                      <TableCell align="center"> <StatusLabel status={row.progress} /> </TableCell>                      
                      <TableCell align="center"><Label color={row.status === 'Active' ? 'success' :'error'}>{sentenceCase(row.status)}</Label></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Container>
    </>
  );
}
