import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Card,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  Autocomplete,
  TextField,
  CardContent,
  Grid,
  Button,
  Switch,
  FormControlLabel 
} from '@mui/material';
import Iconify from '../components/iconify';

const roles = [
  { label: 'Admin', value: 'Admin' },
  { label: 'User', value: 'User' },
  { label: 'Guest', value: 'Guest' },
];

export default function CreateUserPage() {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const password = useWatch({ control, name: 'password', defaultValue: '' }); 
  
  React.useEffect(() => {
    setValue('isActive', true);
  }, [setValue]);

  const isActive = useWatch({
    control,
    name: 'isActive',
    defaultValue: true,
  });

  const linknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
    color: 'rgb(33, 43, 54)',
  };

  const actlinknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
  };

  return (
    <>
      <Helmet>
        <title> Create User | BRI Middleware </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              Create User
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <Iconify icon="ic:baseline-circle" width="6px" borderRadius="50%" color="rgb(145, 158, 171)" />
              }
            >
              <Link style={linknav} to="/dashboard">
                Dashboard
              </Link>
              <Link style={linknav} to="/userMaster">
                User Master
              </Link>
              <Typography style={actlinknav} color="text.secondary">
                Create User
              </Typography>
            </Breadcrumbs>
          </div>
          <div>
            {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Machinery
            </Button> */}
          </div>
        </Stack>

        <Card>
          <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}> {/* Full width on mobile */}
          <TextField
            label="First Name"
            fullWidth
            {...register('firstName', { required: 'First name is required' })}
            error={!!errors.firstName}
            helperText={errors.firstName?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Last Name"
            fullWidth
            {...register('lastName', { required: 'Last name is required' })}
            error={!!errors.lastName}
            helperText={errors.lastName?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Username"
            fullWidth
            {...register('username', { required: 'Username is required' })}
            error={!!errors.username}
            helperText={errors.username?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Password"
            type="password"
            fullWidth
            {...register('password', { required: 'Password is required' })}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            {...register('confirmPassword', {
              required: 'Confirm Password is required',
              validate: (value) => value === password || 'Passwords do not match',
            })}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={roles}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Role"
                fullWidth
                {...register('role', { required: 'Role is required' })}
                error={!!errors.role}
                helperText={errors.role?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container alignItems="center">
            <Grid item mr={3}> {/* Left part of the label */}
              <Typography>Status</Typography>
            </Grid>
            <Grid item > {/* Switch component */}
              <FormControlLabel
                control={
                  <Switch
                    {...register('isActive')}
                    name="isActive"
                    color="primary"
                    defaultChecked
                  />
                }
              />
            </Grid>
            <Grid item> {/* Right part of the label */}
              <Typography>{isActive ? 'Active' : 'Inactive'}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}  style={{ textAlign: 'right' }}>
          <Button type="submit" variant="contained" color="primary" >
            Create
          </Button>
        </Grid>
      </Grid>
    </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
