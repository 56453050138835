import React from 'react';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import { IconButton } from '@mui/material';

function ApiToast() {
  return (
    <div>
      <Toaster position="bottom-left" reverseOrder={false} toastOptions={{
    // Define default options
    className: '',
    duration: 5000,
    style: {
      maxWidth: '500px'
    }
  }}>
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading'}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </div>
  );
}

export default ApiToast;
