import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import axios from "axios";
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import {APICONFIG} from '../../../config/apiConfig';
import {decryptFieldUsingAES, detectBrowser,detectOS,encryptFieldUsingAES} from "../../../utils/help";

// ----------------------------------------------------------------------

export default function LoginForm() {

  const navigate = useNavigate();
  const [apiBaseUrl,setApiBaseUrl]=useState(`${APICONFIG.HOST}/${APICONFIG.API_PREFIX}/${APICONFIG.QC.ROOT}`);
  const [isDisabled, setDisabled] = useState(false);
  const [errorMsg,setErrMsg]=useState(false);

  const request = {
    "Username": "",
    "Password": "",
    "ErrorMessage": "",
    "ErrorCode": "",
    "UserModule": ""
  }
  const initialValues = { username: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const { control, handleSubmit, formState: { errors } } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  
  const [formErrors, setFormErrors] = useState({});
  const handleClick = async (e) => {
    setDisabled(true);
    setErrMsg(false);
   
  //  dispatch({ type: "LOGIN_START" });
   
   try{

    request.Username=e.username;//  encryptFieldUsingAES(e.username);
    request.Password=e.password;

    console.log(`${apiBaseUrl}/login`)
    const res = await axios.post(`${apiBaseUrl}/login`,request);

    const authData={access_token:res.data.data.token,user:res.data.data.user,session_id:res.data.data.session_id,remember:e.remember};
    const authPermission={permission:res.data.data.permission}
    const encry=encryptFieldUsingAES(JSON.stringify(authData));
    // Cookies.set("auth",encry);
     localStorage.setItem("auth",encry);
     const authPermissionEncry=encryptFieldUsingAES(JSON.stringify(authPermission));
     localStorage.setItem("authPermission",authPermissionEncry);
    // Cookies.set("authPermission",authPermissionEncry);

   // const a=Cookies.get("auth");
    // onsole.log(JSON.parse(decryptFieldUsingAES(a)),"Encrypt to decrypt");
    
    navigate('/finalQcList', { replace: true });
   }catch(e)
   {
   console.log(e, "Login error");
    setErrMsg(true);
   //  console.log(errorMsg,'error messgae');
   }
   setDisabled(false);
  };

  return (
    <>
        <form onSubmit={handleSubmit(handleClick)}>
          <Stack spacing={3}>
          <Controller
              name="username" // Changed from "email" to "username"
              control={control}
              defaultValue=""
              rules={{ required: 'Username is required' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Username" // Changed from "Email address" to "Username"
                  error={!!errors.username}
                  helperText={errors.username?.message}
                  {...field}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: 'Password is required' }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  error={!!errors.password}
                  helperText={errors.password?.message}
                  InputProps={{
                    ...field,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Stack>

          {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Controller
              name="remember"
              control={control}
              defaultValue={false}
              render={({ field }) => <Checkbox {...field} label="Remember me" />}
            />
            <Link variant="subtitle2" underline="hover">
              Forgot password?
            </Link>
          </Stack> */}
           {errorMsg?<Label color="error">Username and Password not exists.</Label>:''}
           <><br /><LoadingButton fullWidth size="large" type="submit" variant="contained">
            Login
          </LoadingButton></>
        </form>
    </>
  );
}
