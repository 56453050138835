import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import {
  Card,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  CardContent,
  Grid,
  Button,
  TextField,
  Autocomplete,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Paper,
  Divider,
  ButtonGroup,
  Switch,
  Tooltip,
  IconButton,
  DialogContentText
} from '@mui/material';

import axios from "axios";
import toast from 'react-hot-toast';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Label from '../components/label';
import Iconify from '../components/iconify';
import StatusLabel from '../components/status';
import { APICONFIG } from '../config/apiConfig';
import WoopDialogBox from '../components/woop-dialog-box';
import { getAuthData, validatePermission } from "../utils/help";

const departmentList1 = [
  { label: 'CNC', value: 'CNC' },
  { label: 'Letter Molding', value: 'Letter Molding' },
  { label: 'Fabrication', value: 'Fabrication' },
  { label: 'Polishing', value: 'Polishing' },
  { label: 'Sanding', value: 'Sanding' },
  { label: 'Acrylic', value: 'Acrylic' },
  { label: 'Electrical', value: 'Electrical' },
  { label: 'Screen Printing', value: 'Screen Printing' },
  { label: 'Vinyl', value: 'Vinyl' },
  { label: 'Packaging', value: 'Packaging' },
  { label: 'OUTSOURCED & FIXING MATERIALS', value: 'OUTSOURCED & FIXING MATERIALS' },
];

const workorderList = [
  { label: 'WO-12453', value: 'WO-12453' },
  { label: 'WO-12454', value: 'WO-12454' },
  { label: 'WO-12455', value: 'WO-12455' },
];

const row = {
  workOrderT: 'WO-12453',
  projectID: 'BRI UAE-J1-08-23',
  projectType: 'Projects',
  signType: 'Sign C 8.2',
  salesPerson: 'Nimesh',
  location: 'UAQ/Dubai',
  status: 'In Progress',
  plannedBy: 'Based on Login',
  lastModifiedBy: 'based on User',
};

function createData(operation, qcPm, qcPmStatus, qcPm1, qcPm1Status, qcPm2, qcPm2Status, qcPm3, qcPm3Status, qcPm4, qcPm4Status, qcPm5, qcPm5Status, qcPm6, qcPm6Status, status) {
  return { operation, qcPm, qcPmStatus, qcPm1, qcPm1Status, qcPm2, qcPm2Status, qcPm3, qcPm3Status, qcPm4, qcPm4Status, qcPm5, qcPm5Status, qcPm6, qcPm6Status, status };
}

const rows = [
  createData('CNC', 'Verify Cutting Files', 'Yes', 'Material Types', 'Yes', 'Cutting Quality', 'Yes', 'Quantity', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', '-', 'Yes', 'Yes'),
  createData('Letter Molding', 'Depth of Material', 'Yes', 'Surface Finish', 'Yes', 'Edges and Side Finish', 'Yes', 'Quantity', 'Yes', 'Workmanship', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', 'Yes'),
  createData('Fabrication', 'Material Type / Size', 'Yes', 'Stretcheral Support', 'Yes', 'Surface Finish', 'Yes', 'Fixing Methods & Assembly', 'Yes', 'Quantity', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', 'Yes'),
  createData('Polishing', 'Surface Finish', 'Yes', 'Workmanship', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', '-', 'Yes', '-', 'Yes', '-', 'Yes', 'Yes'),
  createData('Sanding', 'Surface Finish', 'Yes', 'Powder Costing Coat', 'Yes', 'Workmanship', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', '-', 'Yes', '-', 'Yes', 'Yes'),
  createData('Painting', 'Colour / Coat', 'Yes', 'Surface Finish', 'Yes', 'Quantity', 'Yes', 'Workmanship', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', '-', 'Yes', 'Yes'),
  createData('Acrylic', 'Material Specification', 'Yes', 'Surface Finish', 'Yes', 'Quantity', 'Yes', 'Fixing', 'Yes', 'Workmanship', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', 'Yes'),
  createData('Electrical', 'LED Brand', 'Yes', 'KELVIN temperature & illumination', 'Yes', 'Verify Electircal Components', 'Yes', 'Visual Checkup (Darkspots)', 'Yes', 'Quantity', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', 'Yes'),
  createData('Screen Printing', 'Material Specification', 'Yes', 'Surface Finish', 'Yes', 'Print Quality', 'Yes', 'Workmanship', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', '-', 'Yes', 'Yes'),
  createData('Vinyl', 'Material Specification', 'Yes', 'Surface Finish', 'Yes', 'Print Quality', 'Yes', 'Workmanship', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', '-', 'Yes', 'Yes'),
  createData('Packaging', 'Cleaning', 'Yes', 'Physical Damages', 'Yes', 'Quantity', 'Yes', 'Workmanship', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', '-', 'Yes', 'Yes'),
  createData('OUTSOURCED & FIXING MATERIALS', 'Material Specification', 'Yes', 'Surafce Finish', 'Yes', 'Quantity', 'Yes', 'Workmanship', 'Yes', 'Prior Department QC Problems', 'Yes', '-', 'Yes', '-', 'Yes', 'Yes'),
];
const originalRow = rows;
const req = { department: '', department_id: 0, product_img: '', remarks: '', qcData: [{ label: '', value: '', img: '', remarks: '', previousDepartment: '', previousDepartmentId: 0 }] }

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontWeight: 'bold',
  },
}));
const dtNow = new Date();
export default function DpWoOperationPlanning() {
  const [auth, setAuth] = useState(getAuthData());
  const [tableData, setTableData] = useState(rows);

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [priorDepartmentEnable, setPriorDepartmentEnable] = useState(false);
  const [apiBaseUrl, setApiBaseUrl] = useState(`${APICONFIG.HOST}/${APICONFIG.API_PREFIX}/${APICONFIG.QC.ROOT}`);
  const [woDrpList, setWoDrpList] = useState([]);
  const [woDetails, setWoSelDetails] = useState({});
  const [woQcDetails, setWoQcDetails] = useState([]);
  const [departmentList, setdepartmentList] = useState([]);
  const [departmentQcList, setdepartmenQctList] = useState([]);
  const [popUpDepartmentList, setPopUpDepartmentList] = useState([]);
  const [qcFormReq, setQcFormRequest] = useState([]);
  const [base64, setBase64String] = useState('');
  const [remarks, setTextBoxValue] = useState('');
  const [cellIndex, setCellIndex] = useState(-1);
  const [woNo, setWoNo] = useState('');
  const [previousDepartment, setpreviousDepartment] = useState('');
  const [remarksError, setRemarksError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [prevDepError, setprevDepError] = useState(false);
  const [isdataUpdated, setUpDateStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleStatusToggle = (row, nth, qcpm) => {
    try {
      setUpDateStatus(false);
      localStorage.setItem('dataUpStatu', 0);
      setRemarksError(false);
      setFileError(false);
      setprevDepError(false);
      // Clone the table data to avoid mutating the original state
      const updatedTableData = [...tableData];
      setCellIndex(nth);
      // Find the index of the row in the table data
      const rowIndex = updatedTableData.findIndex((dataRow) => dataRow.operation === row.operation);

      // Toggle the status for the specific row

      if (qcpm === 'Prior Department QC Problems') {

        setPriorDepartmentEnable(true);
      } else {
        setPriorDepartmentEnable(false);
      }

      if (nth === 0) {

        updatedTableData[rowIndex].qcPmStatus = row.qcPmStatus === 'Yes' ? 'No' : 'Yes';
        if (updatedTableData[rowIndex].qcPmStatus === 'No') {
          setHoldDialogOpen(true);
        }

      } else if (nth === 1) {
        updatedTableData[rowIndex].qcPm1Status = row.qcPm1Status === 'Yes' ? 'No' : 'Yes';
        if (updatedTableData[rowIndex].qcPm1Status === 'No') {
          setHoldDialogOpen(true);
        }
      } else if (nth === 2) {
        updatedTableData[rowIndex].qcPm2Status = row.qcPm2Status === 'Yes' ? 'No' : 'Yes';
        if (updatedTableData[rowIndex].qcPm2Status === 'No') {
          setHoldDialogOpen(true);
        }
      } else if (nth === 3) {
        updatedTableData[rowIndex].qcPm3Status = row.qcPm3Status === 'Yes' ? 'No' : 'Yes';
        if (updatedTableData[rowIndex].qcPm3Status === 'No') {
          setHoldDialogOpen(true);
        }
      } else if (nth === 4) {
        updatedTableData[rowIndex].qcPm4Status = row.qcPm4Status === 'Yes' ? 'No' : 'Yes';
        if (updatedTableData[rowIndex].qcPm4Status === 'No') {
          setHoldDialogOpen(true);
        }
      } else if (nth === 5) {
        updatedTableData[rowIndex].qcPm5Status = row.qcPm5Status === 'Yes' ? 'No' : 'Yes';
        if (updatedTableData[rowIndex].qcPm5Status === 'No') {


          setHoldDialogOpen(true);
        }
      } else if (nth === 6) {
        updatedTableData[rowIndex].qcPm6Status = row.qcPm6Status === 'Yes' ? 'No' : 'Yes';
        if (updatedTableData[rowIndex].qcPm6Status === 'No') {
          setHoldDialogOpen(true);
        }
      }
      // Update the table data state
      setTableData(updatedTableData);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchWoList = async () => {
    try {

      const res = await axios.get(`${apiBaseUrl}/${APICONFIG.QC.RANDOM_QC_WO_LIST}/${10}`, {
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${authData.access_token}`
        }
      });
      setWoQcDetails(res.data.data);
      const woList = res.data.data.map((item, index) => (
        {
          value: item.WorkOrderID,
          label: item.WorkOrderNo
        }

      ));
      setWoDrpList(woList);

    } catch (e) {
      console.log(e);
    }
  }

  const fetchWoDepartmentQcList = async (woId) => {
    try {

      const obj = woQcDetails.find(x => x.WorkOrderID === woId);
      setWoSelDetails(obj);

      const res = await axios.get(`${apiBaseUrl}/${APICONFIG.QC.RANDOM_QC_PARAM_LIST}/${woId}`, {
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${authData.access_token}`
        }
      });

      const dpList = res.data.data.map((item, index) => (
        {
          value: item.department,
          label: item.department
        }

      ));
      // rows=[];
      // res.data.data.each((index, item)=>{
      //   if(item.department=='Letter Molding')
      //     createData(item.department, d.Param,'Yes', 'Material Types','Yes', 'Cutting Quality','Yes', 'Quantity','Yes', 'Prior Department QC Problems','Yes','-','Yes','-','Yes','Yes')]


      // })
      setdepartmenQctList(res.data.data);
      setdepartmentList(dpList);

    } catch (e) {
      console.log(e);
    }
  }


  useEffect(() => {
    fetchWoList();
    DepartmentList();
  }, []);


  const [holdDialogOpen, setHoldDialogOpen] = useState(false);


  const holdHandleDialogClose = () => {
    try {
      const dataStatus = localStorage.getItem('dataUpStatu');
      const updatedTableData = [...tableData];

      const rowIndex = updatedTableData.findIndex((dataRow) => dataRow.operation === selectedDepartment);

      if (dataStatus === '0') {
        if (cellIndex === 0) {
          if (updatedTableData[rowIndex].qcPmStatus === 'No' && dataStatus === '0') {
            updatedTableData[rowIndex].qcPmStatus = 'Yes';
            setTextBoxValue('');
            setBase64String('');
          }
        } else if (cellIndex === 1) {
          if (updatedTableData[rowIndex].qcPm1Status === 'No' && dataStatus === '0') {
            updatedTableData[rowIndex].qcPm1Status = 'Yes';
            setTextBoxValue('');
            setBase64String('');
          }
        } else if (cellIndex === 2) {
          if (updatedTableData[rowIndex].qcPm2Status === 'No' && dataStatus === '0') {
            updatedTableData[rowIndex].qcPm2Status = 'Yes';
            setTextBoxValue('');
            setBase64String('');
          }
        }
        else if (cellIndex === 3) {
          if (updatedTableData[rowIndex].qcPm3Status === 'No' && dataStatus === '0') {
            updatedTableData[rowIndex].qcPm3Status = 'Yes';
            setTextBoxValue('');
            setBase64String('');
          }
        } else if (cellIndex === 4) {
          if (updatedTableData[rowIndex].qcPm4Status === 'No' && dataStatus === '0') {
            updatedTableData[rowIndex].qcPm4Status = 'Yes';
            setTextBoxValue('');
            setBase64String('');
          }
        } else if (cellIndex === 5) {
          if (updatedTableData[rowIndex].qcPm5Status === 'No' && dataStatus === '0') {
            updatedTableData[rowIndex].qcPm5Status = 'Yes';
            setTextBoxValue('');
            setBase64String('');
          }
        }
        else if (cellIndex === 6) {
          if (updatedTableData[rowIndex].qcPm6Status === 'No' && dataStatus === '0') {
            updatedTableData[rowIndex].qcPm6Status = 'Yes';
            setTextBoxValue('');
            setBase64String('');
          }
        }
        if (cellIndex === 111) {
          setTextBoxValue('');
          setBase64String('');
        }
        if (dataStatus === '0') {
          setTableData(updatedTableData);
        }
      }
      setHoldDialogOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  const holdWorkorderHandleDialogConfirm = async () => {
    try {
      // eslint-disable-next-line no-debugger
      debugger;
      console.log(isdataUpdated, 'Work order Hold');
      localStorage.setItem('dataUpStatu', 1);

      let validation = false;
      if (remarks === '' || remarks === null) {
        setRemarksError(true);
        // eslint-disable-next-line no-const-assign
        validation = true;
      }
      if (base64 === '' || base64 === null) {
        setFileError(true);
        validation = true;
      }
      localStorage.setItem('dataUpStatu', 1);
      if ((previousDepartment === '' || previousDepartment === null) && priorDepartmentEnable) {
        setprevDepError(true);
        validation = true;
      }

      if (!validation) {
        setRemarksError(false);
        setFileError(false);
        setprevDepError(false);
        if (cellIndex > -1 && cellIndex !== 111) {

          const rowIndex = 0;

          if (cellIndex === 0) {
            console.log(qcFormReq, ' form request Data');
            const i = qcFormReq.qcData.findIndex(x => x.param === rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm);
            console.log(i, ' index');
            qcFormReq.qcData[i].paramValue = rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPmStatus;
            qcFormReq.qcData[i].img = base64;
            qcFormReq.qcData[i].remarks = remarks;
            qcFormReq.qcData[i].previousDepartmentId = previousDepartment;
          } else if (cellIndex === 1) {
            const i = qcFormReq.qcData.findIndex(x => x.param === rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm1);
            qcFormReq.qcData[i].paramValue = rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm1Status;
            qcFormReq.qcData[i].img = base64;
            qcFormReq.qcData[i].remarks = remarks;
            qcFormReq.qcData[i].previousDepartmentId = previousDepartment;
            // qcFormReq.qcData[cellIndex].paramValue=  rows.filter(x=>x.operation===selectedDepartment)[rowIndex].qcPm1Status ;  

          } else if (cellIndex === 2) {
            const i = qcFormReq.qcData.findIndex(x => x.param === rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm2);
            qcFormReq.qcData[i].paramValue = rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm2Status;
            qcFormReq.qcData[i].img = base64;
            qcFormReq.qcData[i].remarks = remarks;
            qcFormReq.qcData[i].previousDepartmentId = previousDepartment;
            // qcFormReq.qcData[cellIndex].paramValue=  rows.filter(x=>x.operation===selectedDepartment)[rowIndex].qcPm2Status;  

          } else if (cellIndex === 3) {
            console.log(qcFormReq.qcData, ' qcFormReq.qcData');
            console.log(rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm3, ' rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm3');
            console.log(selectedDepartment, ' selectedDepartment');
            console.log(rows, ' rows');
            const i = qcFormReq.qcData.findIndex(x => x.param === rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm3);
            qcFormReq.qcData[i].paramValue = rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm3Status;
            qcFormReq.qcData[i].img = base64;
            qcFormReq.qcData[i].remarks = remarks;
            qcFormReq.qcData[i].previousDepartmentId = previousDepartment;
            // qcFormReq.qcData[cellIndex].paramValue=  rows.filter(x=>x.operation===selectedDepartment)[rowIndex].qcPm3Status;  

          } else if (cellIndex === 4) {
            const i = qcFormReq.qcData.findIndex(x => x.param === rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm4);
            qcFormReq.qcData[i].paramValue = rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm4Status;
            qcFormReq.qcData[i].img = base64;
            qcFormReq.qcData[i].remarks = remarks;
            qcFormReq.qcData[i].previousDepartmentId = previousDepartment;
            // qcFormReq.qcData[cellIndex].paramValue= rows.filter(x=>x.operation===selectedDepartment)[rowIndex].qcPm4Status ;  

          } else if (cellIndex === 5) {
            const i = qcFormReq.qcData.findIndex(x => x.param === rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm5);
            qcFormReq.qcData[i].paramValue = rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm5Status;
            qcFormReq.qcData[i].img = base64;
            qcFormReq.qcData[i].remarks = remarks;
            qcFormReq.qcData[i].previousDepartmentId = previousDepartment;
            // qcFormReq.qcData[cellIndex].paramValue= rows.filter(x=>x.operation===selectedDepartment)[rowIndex].qcPm5Status;  

          } else if (cellIndex === 6) {
            const i = qcFormReq.qcData.findIndex(x => x.param === rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm6);
            qcFormReq.qcData[i].paramValue = rows.filter(x => x.operation === selectedDepartment)[rowIndex].qcPm6Status;
            qcFormReq.qcData[i].img = base64;
            qcFormReq.qcData[i].remarks = remarks;
            qcFormReq.qcData[i].previousDepartmentId = previousDepartment;
            // qcFormReq.qcData[cellIndex].paramValue= rows.filter(x=>x.operation===selectedDepartment)[rowIndex].qcPm6Status ;  

          }

          //  qcFormReq.data[cellIndex].paramValue='';

        } else if (cellIndex === 111) {
          setLoading(true);
          qcFormReq.remarks = remarks;
          qcFormReq.product_img = base64;
          qcFormReq.user_id = auth.user.userProfile.userID;
          qcFormReq.woNo = woNo;
          try {
            const resPermission = await axios.post(`${apiBaseUrl}/${APICONFIG.QC.RANDOM_QC_FORM_SUBMIT}`, qcFormReq, {
              headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${authData.access_token}`
              }
            });

            toast.success('QC Successfully Completed!');
            // navigate('/randomWoQc', { replace: true });
            setLoading(false);
            window.location.reload();
          } catch (e) { console.log(e); }

        }
        console.log(qcFormReq, 'qcFormReq');
        setQcFormRequest(qcFormReq);

        holdHandleDialogClose();
        setCellIndex(-1);
        setBase64String(null);
        setTextBoxValue('');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const DepartmentList = async () => {
    try {



      const res = await axios.get(`${apiBaseUrl}/${APICONFIG.QC.LOAD_DEPARTMENT_LIST}/${10}`, {
        headers: {
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${authData.access_token}`
        }
      });

      const dpList = res.data.data.map((item, index) => (
        {
          value: item.DepartmentID,
          label: item.DepartmentName
        }

      ));
      // rows=[];
      // res.data.data.each((index, item)=>{
      //   if(item.department=='Letter Molding')
      //     createData(item.department, d.Param,'Yes', 'Material Types','Yes', 'Cutting Quality','Yes', 'Quantity','Yes', 'Prior Department QC Problems','Yes','-','Yes','-','Yes','Yes')]


      // })
      console.log(dpList, 'dpList');
      setPopUpDepartmentList(dpList);

    } catch (e) {
      console.log(e);
    }
  }

  const cancelHandleDialogClose = () => {
    setCancelDialogOpen(false);
  };

  const cancelWorkorderHandleDialogConfirm = () => {
    console.log('Work order Cancel');
    toast.success('Workorder Successfully Placed on Cancel!');
    cancelHandleDialogClose();
  };

  const cancelProjectHandleDialogConfirm = () => {
    console.log('Project Cancel');
    toast.success('Project Successfully Placed on Cancel!');
    cancelHandleDialogClose();
  };

  const saveQcForm = () => {
    try {

      setCellIndex(111);

      setPriorDepartmentEnable(false);
      setHoldDialogOpen(true);
    } catch (e) {
      console.log(e);
    }
  }

  const linknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
    color: 'rgb(33, 43, 54)',
  };

  const actlinknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
  };

  const handleFileInputChange = (event) => {
    try {
      console.log('testing file');
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          // The result attribute contains the data as a base64 encoded string
          const base64String = reader.result;
          setBase64String(base64String);
          console.log(base64String, ' file base 64');
        };

        // Read the file as a Data URL (base64 encoded)
        reader.readAsDataURL(file);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleTextBoxChange = (event) => {
    // Update the state with the new value from the text box
    setTextBoxValue(event.target.value);
    console.log(event.target.value, 'remarks');
  };

  return (
    <>
      <Helmet>
        <title> WO Random QC | BRI Middleware </title>
      </Helmet>

      <Container maxWidth="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              WO Random QC
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <Iconify icon="ic:baseline-circle" width="6px" borderRadius="50%" color="rgb(145, 158, 171)" />
              }
            >
              {/* <Link style={linknav} to="/dashboard">
                Dashboard
              </Link> */}
              {/* <Link style={linknav} to="/machineryMaster">
                Random
              </Link> */}
              <Typography style={actlinknav} color="text.secondary">
                Random QC
              </Typography>
            </Breadcrumbs>
          </div>
          <div>
            {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Machinery
            </Button> */}
          </div>
        </Stack>

        <Grid mb={5}>
          <Card>
            <Grid>
              <Typography sx={{ pt: 2, pb: 2, pl: 2 }}>
                <Label color="info">WORK ORDER DETAILS</Label>
              </Typography>
              <Grid mt={1}>
                <TableContainer component={Paper}>
                  <Table sx={{ maxWidth: '100%' }} size="small" aria-label="caption table">
                    <TableBody>
                      <TableRow>
                        <StyledTableCell2 align="left" width={150}>
                          Work Order:
                        </StyledTableCell2>
                        <TableCell align="left" colSpan={1}>
                          <Autocomplete
                            size="small"
                            options={woDrpList}
                            onChange={(e, v) => {
                              if (v != null) {
                                fetchWoDepartmentQcList(v.value);
                                setWoNo(v.label);
                              } else {
                                console.log(v);
                                setWoNo('');
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Workorder"
                                fullWidth
                              />
                            )}
                            renderOption={(props, option) => (
                              <li key={option} {...props} style={{ fontSize: '0.875rem' }}>
                                {option.label}
                              </li>
                            )}
                          />
                          {/* {row.workOrderT} */}
                        </TableCell>
                        <StyledTableCell2 align="left" width={150}>
                          Department:
                        </StyledTableCell2>
                        <TableCell align="left" colSpan={1}>
                          <Autocomplete
                            size="small"
                            options={departmentList}
                            onChange={(e, v) => {
                              if (v !== null) {

                                // {department:v.label,departmentId:0,product_img:'',remarks:'',qcData:[{label:row.qcPm,value:row.qcPmStatus,img:'',remarks:'',previousDepartment:'',previousDepartmentId:0}]}
                                setSelectedDepartment(v.label);
                                console.log(departmentQcList.filter(x => x.department === v.label), ' departmentQcList.filter(x=>x.department===v.label).data');
                                const qcData = departmentQcList.filter(x => x.department === v.label)[0].data.map((item, index) => (
                                  {
                                    param: item.Param,
                                    paramId: item.QcParamId,
                                    paramValue: 'Yes',
                                    img: '',
                                    remarks: '',
                                    previousDepartment: '',
                                    previousDepartmentId: 0
                                  }

                                ));
                                const request = { woNo: '', user_id: 1, department: v.label, departmentId: 0, product_img: '', remarks: '', qcData }
                                console.log(request, 'formrequest');
                                setQcFormRequest(request);
                                console.log(v.label, ' selected department');
                              } else {
                                setSelectedDepartment(null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Department"
                                fullWidth
                              />
                            )}
                            renderOption={(props, option) => (
                              <li key={option} {...props} style={{ fontSize: '0.875rem' }}>
                                {option.label}
                              </li>
                            )}
                          />
                          {/* {row.workOrderT} */}
                        </TableCell>

                      </TableRow>
                      <TableRow>
                        <StyledTableCell2 align="left" width={150}>Inititator:</StyledTableCell2>
                        <TableCell align="left">{auth.user.userProfile.firstName}</TableCell>
                        <StyledTableCell2 align="left" width={150}>Sign Type:</StyledTableCell2>
                        <TableCell align="left">{woDetails.signType}</TableCell>

                      </TableRow>
                      <TableRow>
                        <StyledTableCell2 align="left">JOB:</StyledTableCell2>
                        <TableCell align="left">{woDetails.ProjectID}</TableCell>
                        <StyledTableCell2 align="left">Drawing:</StyledTableCell2>
                        <TableCell align="left">
                          <Tooltip title="File" placement="top" arrow>
                            <NavLink to={'https://brisigns-my.sharepoint.com/:b:/p/akash_r/EeFQoRILR45AhcmTbem0u7IBAjj29-PGDDP1K6B-fBx7LA?e=xtOcfR'} target='blank'>
                              <IconButton aria-label="fingerprint" color="secondary">
                                <Iconify icon={'eva:file-text-outline'} />
                              </IconButton>
                            </NavLink>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell2 align="left">Date:</StyledTableCell2>


                        <TableCell align="left">{dtNow.getDate()}-{(dtNow.getMonth() + 1) > 9 ? (dtNow.getMonth() + 1) : `0${dtNow.getMonth() + 1}`}-{dtNow.getFullYear()}</TableCell>

                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid>
          <Card>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ minWidth: 25 }}>No</StyledTableCell>
                    <StyledTableCell style={{ minWidth: 125 }}>Deaprtments</StyledTableCell>
                    <StyledTableCell style={{ maxWidth: 200 }} align="center" />
                    <StyledTableCell style={{ maxWidth: 200 }} align="center" />
                    <StyledTableCell style={{ maxWidth: 200 }} align="center" />
                    <StyledTableCell style={{ maxWidth: 200 }} align="center" />
                    <StyledTableCell style={{ maxWidth: 200 }} align="center" />
                    <StyledTableCell style={{ maxWidth: 200 }} align="center" />
                    {/* <StyledTableCell style={{ maxWidth: 200 }} align="center" /> */}
                    <StyledTableCell style={{ maxWidth: 200 }} align="center" >Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.filter(x => x.operation === selectedDepartment).map((row, index) => (
                    <TableRow key={row.operation} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} disabled>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.operation}
                      </TableCell>
                      <TableCell align="center">

                        {row.qcPm}
                        {row.qcPm !== '-' ?
                          <><Switch
                            checked={row.qcPmStatus === 'Yes'}
                            onClick={() => handleStatusToggle(row, 0, row.qcPm)}
                            color="primary" /><Label color={row.qcPmStatus === 'Yes' ? 'success' : 'error'}>{sentenceCase(row.qcPmStatus)}</Label></>
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                        {row.qcPm1}
                        {row.qcPm1 !== '-' ?
                          <><Switch
                            checked={row.qcPm1Status === 'Yes'}
                            onChange={() => handleStatusToggle(row, 1, row.qcPm1)}
                            color="primary" /><Label color={row.qcPm1Status === 'Yes' ? 'success' : 'error'}>{sentenceCase(row.qcPm1Status)}</Label></>
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                        {row.qcPm2}
                        {row.qcPm2 !== '-' ?
                          <><Switch
                            checked={row.qcPm2Status === 'Yes'}
                            onChange={() => handleStatusToggle(row, 2, row.qcPm2)}
                            color="primary" /><Label color={row.qcPm2Status === 'Yes' ? 'success' : 'error'}>{sentenceCase(row.qcPm2Status)}</Label></>
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                        {row.qcPm3}
                        {row.qcPm3 !== '-' ?
                          <><Switch
                            checked={row.qcPm3Status === 'Yes'}
                            onChange={() => handleStatusToggle(row, 3, row.qcPm3)}
                            color="primary" /><Label color={row.qcPm3Status === 'Yes' ? 'success' : 'error'}>{sentenceCase(row.qcPm3Status)}</Label></>
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                        {row.qcPm4}
                        {row.qcPm4 !== '-' ?
                          <><Switch
                            checked={row.qcPm4Status === 'Yes'}
                            onChange={() => handleStatusToggle(row, 4, row.qcPm4)}
                            color="primary" /><Label color={row.qcPm4Status === 'Yes' ? 'success' : 'error'}>{sentenceCase(row.qcPm4Status)}</Label></>
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                        {row.qcPm5}
                        {row.qcPm5 !== '-' ?
                          <><Switch
                            checked={row.qcPm5Status === 'Yes'}
                            onChange={() => handleStatusToggle(row, 5, row.qcPm5)}
                            color="primary" /><Label color={row.qcPm5Status === 'Yes' ? 'success' : 'error'}>{sentenceCase(row.qcPm5Status)}</Label></>
                          : ''}
                      </TableCell>
                      <TableCell align="center">
                        <Button variant="contained" onClick={() => saveQcForm()} color='primary' size="small">
                          Save
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Container>

      <WoopDialogBox
        open={holdDialogOpen}
        onClose={holdHandleDialogClose}
        onConfirm1={holdWorkorderHandleDialogConfirm}
        departmentList={departmentList1}
        btnColor1={'warning'}
        priorDepartmentEnable={priorDepartmentEnable}
        dContent={"Please add comments and upload product image."}
        dTitle={"QC Submition"}
        handleFileInputChange={handleFileInputChange}
        handleTextBoxChange={handleTextBoxChange}
        setpreviousDepartment={setpreviousDepartment}
        fileError={fileError}
        remarksError={remarksError}
        prevDepError={prevDepError}
        loading={loading}
      />

      <WoopDialogBox
        open={cancelDialogOpen}
        onClose={cancelHandleDialogClose}
        onConfirm1={cancelWorkorderHandleDialogConfirm}
        onConfirm2={cancelProjectHandleDialogConfirm}
        btnColor1={'warning'}
        btnColor2={'error'}
        dContent={"Please confirm which action you want to place on cancel?"}
        dTitle={"Confirm Cancel Action"}
        btnText1={'workorder'}
        btnText2={'Project'}
      />
    </>
  );
}
