import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Card,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  Autocomplete,
  TextField,
  CardContent,
  Grid,
  Button,
  Switch,
  FormControlLabel,
} from '@mui/material';
import Iconify from '../components/iconify';

const roles = [
  { label: 'Admin', value: 'Admin' },
  { label: 'User', value: 'User' },
  { label: 'Guest', value: 'Guest' },
];

export default function CreateEmplyoeePage() {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const password = useWatch({ control, name: 'password', defaultValue: '' });

  React.useEffect(() => {
    setValue('isActive', true);
  }, [setValue]);

  const isActive = useWatch({
    control,
    name: 'isActive',
    defaultValue: true,
  });

  const linknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
    color: 'rgb(33, 43, 54)',
  };

  const actlinknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
  };

  return (
    <>
      <Helmet>
        <title> Create Employee | BRI Middleware </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              Create Employee
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <Iconify icon="ic:baseline-circle" width="6px" borderRadius="50%" color="rgb(145, 158, 171)" />
              }
            >
              <Link style={linknav} to="/dashboard">
                Dashboard
              </Link>
              <Link style={linknav} to="/userMaster">
                Employee Master
              </Link>
              <Typography style={actlinknav} color="text.secondary">
                Create Employee
              </Typography>
            </Breadcrumbs>
          </div>
          <div>
            {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Machinery
            </Button> */}
          </div>
        </Stack>

        <Card>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <TextField
                    label="Employee Name"
                    fullWidth
                    {...register('emp_Name', { required: 'Employee name is required' })}
                    error={!!errors.emp_Name}
                    helperText={errors.emp_Name?.message}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  {' '}
                  {/* Full width on mobile */}
                  <TextField
                    label="Employee ID"
                    fullWidth
                    {...register('emp_id', { required: 'Employee ID is required' })}
                    error={!!errors.emp_id}
                    helperText={errors.emp_id?.message}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Autocomplete
                    options={roles}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Department"
                        fullWidth
                        {...register('department', { required: 'Department is required' })}
                        error={!!errors.department}
                        helperText={errors.department?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container alignItems="center" justifyContent={'center'} pt={"10px"}>
                    <Grid item mr={3}>
                      {' '}
                      {/* Left part of the label */}
                      <Typography>Status</Typography>
                    </Grid>
                    <Grid item>
                      {' '}
                      {/* Switch component */}
                      <FormControlLabel
                        control={<Switch {...register('isActive')} name="isActive" color="primary" defaultChecked />}
                      />
                    </Grid>
                    <Grid item>
                      {' '}
                      {/* Right part of the label */}
                      <Typography>{isActive ? 'Active' : 'Inactive'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button type="submit" variant="contained" color="primary">
                    Create
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
