import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import {
  Card,
  Stack,
  Container,
  Typography,
  Breadcrumbs,
  Autocomplete,
  TextField,
  CardContent,
  Grid,
  Button,
  FormControlLabel,
  Switch,
} from '@mui/material';
import Iconify from '../components/iconify';

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
];

export default function CreateEmailAlertPage() {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const filter = (options, params) => {
    const filtered = options.filter((option) => {
      return option.toLowerCase().includes(params.inputValue.toLowerCase());
    });

    if (params.inputValue !== '') {
      filtered.push(params.inputValue);
    }

    return filtered;
  };

  const onSubmit = (data) => {
    console.log(data); // Handle form submission
  };

  React.useEffect(() => {
    setValue('isActive', true);
  }, [setValue]);

  const isActive = useWatch({
    control,
    name: 'isActive',
    defaultValue: true,
  });

  const linknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
    color: 'rgb(33, 43, 54)',
  };

  const actlinknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
  };

  return (
    <>
      <Helmet>
        <title> Create Email Alert | BRI Middleware </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              Create Email Alert
            </Typography>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <Iconify icon="ic:baseline-circle" width="6px" borderRadius="50%" color="rgb(145, 158, 171)" />
              }
            >
              <Link style={linknav} to="/dashboard">
                Dashboard
              </Link>
              <Link style={linknav} to="/EmailAlertMaster">
                Email Alert Master
              </Link>
              <Typography style={actlinknav} color="text.secondary">
                Create Email Alert
              </Typography>
            </Breadcrumbs>
          </div>
          <div>
            {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Machinery
            </Button> */}
          </div>
        </Stack>

        <Card>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid container spacing={2} mt={1} mb={1}>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      options={top100Films}
                      onChange={(event, newValue) => {
                        // Set the field value and trigger validation
                        setValue('emails', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Emails"
                          fullWidth
                          error={!!errors.emails}
                          helperText={errors.emails?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      label="Alert Name"
                      fullWidth
                      {...register('alert_name', { required: 'Alert Name is required' })}
                      error={!!errors.alert_name}
                      helperText={errors.alert_name?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Grid container alignItems="center" justifyContent={'center'} pt={'10px'}>
                      <Grid item mr={3}>
                        {' '}
                        {/* Left part of the label */}
                        <Typography>Status</Typography>
                      </Grid>
                      <Grid item>
                        {' '}
                        {/* Switch component */}
                        <FormControlLabel
                          control={<Switch {...register('isActive')} name="isActive" color="primary" defaultChecked />}
                        />
                      </Grid>
                      <Grid item>
                        {' '}
                        {/* Right part of the label */}
                        <Typography>{isActive ? 'Active' : 'Inactive'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <Button type="submit" variant="contained" color="primary">
                    Create
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
