import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  styled,
  tableCellClasses,
  Card,
  CircularProgress,
  Box,
  Tooltip,
  IconButton,
} from '@mui/material';
import Iconify from "../iconify";
import Label from '../label';
import { APICONFIG } from '../../config/apiConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(empId, empName, time) {
  return { empId, empName, time };
}

const rows = [createData('E0607', 'JOSEPH JAMES', 10), createData('E1009', 'ABISH ANTONY XAVIEM', 10)];

const dummyData = [
  { orderNumber: 1, materialDetails: 'Material A', materialSelected: true, quantity: 10 },
  { orderNumber: 2, materialDetails: 'Material B', materialSelected: false, quantity: 5 },
  { orderNumber: 3, materialDetails: 'Material C', materialSelected: true, quantity: 1 },
  { orderNumber: 4, materialDetails: 'Material D', materialSelected: false, quantity: 3 },
];

const RandomQCViewListDialogBox = ({ open, onClose, dataPop, onConfirm }) => {
  const [mrdDetails, setMrdDetails] = useState([]);

  const handleClose = () => {

    onClose();
  };

  const handleConfirm = () => {
    handleClose();
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    if (open) {
      setData(dataPop)
      setLoading(false);
    }
  }, [open]);
 const dialogStyle = {
    borderRadius: '12px',
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth PaperProps={{ style: dialogStyle }}>
      <DialogTitle sx={{ fontWeight: 200 }}><span style={{ color: 'GrayText', fontWeight: 700 }}> </span>Random QC History</DialogTitle>
      <DialogContent>
        <Card sx={{ mb: 5 }} style={{ overflow: 'auto' }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: 2 }}>Sl.No</StyledTableCell>
                <StyledTableCell sx={{ width: 2 }} align="left">Department</StyledTableCell>
                <StyledTableCell sx={{ width: 2 }} align="left">QC No</StyledTableCell>
                {/* <StyledTableCell sx={{width:2}} align="left">Image</StyledTableCell>
              <StyledTableCell align="left">Remarks</StyledTableCell> */}
                <StyledTableCell align="left" sx={{ width: 30 }} />
                <StyledTableCell align="left" sx={{ width: 30 }} />
                <StyledTableCell align="left" sx={{ width: 30 }} />
                <StyledTableCell align="left" sx={{ width: 30 }} />
                <StyledTableCell align="left" sx={{ width: 30 }} />
                <StyledTableCell align="left" sx={{ width: 30 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <StyledTableCell colSpan={3} align="center">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <CircularProgress size={30} sx={{ color: 'black' }} />
                    </Box>
                    Loading...
                  </StyledTableCell>
                </TableRow>
              ) : data.length === 0 ? (
                <TableRow>
                  <StyledTableCell colSpan={3} align="center">No data found</StyledTableCell>
                </TableRow>
              ) : (
                data.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell component="th" scope="row">{index + 1}</StyledTableCell>
                    <StyledTableCell align="left">{row.DepartmentId}  <Tooltip title="File" placement="top" arrow>
                      <NavLink to={`${APICONFIG.HOST}/api/qc/file_render?url=${row.ProductImg}`} target='blank'>
                        <IconButton aria-label="fingerprint" color="secondary">
                          <Iconify icon={'eva:file-text-outline'} />
                        </IconButton>
                      </NavLink>
                    </Tooltip>
                      <Tooltip title={row.Remarks} placement="top" arrow>

                        <Iconify icon={'la:comments-solid'} />

                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.QcId}</StyledTableCell>
                    {row.QcParams.map((p, index) => (
                      // eslint-disable-next-line react/jsx-key
                      <StyledTableCell align="left"><Label color={p.paramValue === "Yes" ? "success" : "error"}>{p.param}</Label>
                      <br/><b>{p.param==='Prior Department QC Problems'?((p.previousDepartmentId!=='' && p.previousDepartmentId!==0)?p.previousDepartmentId:''):''}</b>
                        {p.paramValue === "Yes" ? "" : <><Tooltip title="File" placement="top" arrow>
                          <NavLink to={`${APICONFIG.HOST}/api/qc/file_render?url=${p.img}`} target='blank'>
                            <IconButton aria-label="fingerprint" color="secondary">
                              <Iconify icon={'eva:file-text-outline'} />
                            </IconButton>
                          </NavLink>
                        </Tooltip><Tooltip title={p.remarks} placement="top" arrow>

                            <Iconify icon={'la:comments-solid'} />

                          </Tooltip></>
                        }
                      </StyledTableCell>
                    ))}
                  </TableRow>

                ))
              )}
            </TableBody>
          </Table>
        </Card>
      </DialogContent>
      <DialogActions sx={{ pr: 3, pb: 2 }}>
        <Button onClick={handleClose} variant="outlined" style={{ borderColor: 'black', color: 'black' }} size="small">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RandomQCViewListDialogBox;
