import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Breadcrumbs,
  Tooltip,
  ButtonGroup,
  Grid,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections

import { WorkOrderHead, WorkOrderToolBar } from '../sections/@dashboard/workOrder';
import { OperationActivitesToolbar } from '../sections/@dashboard/operationActivites';
import StatusLabel from '../components/status';
import DialogBox from '../components/dialog-box';
import StartWorkDialogBox from '../components/start-work-dialog-box';
import PauseWorkDialogBox from '../components/pause-work-dialog-box';
import MrdDialogBox from '../components/mrd-dialog-box';
import MrdCompletionDialogBox from '../components/mrd-completion-dialog-box';
import HoldWorkDialogBox from '../components/hold-work-dialog-box';
import MrdHoldDialogBox from '../components/mrd-hold-dialog-box';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'slNo', label: 'Sl.No', alignRight: false },
  { id: 'SignType', label: 'Sign Type', alignRight: false },
  { id: 'WO', label: 'WO', alignRight: false },
  { id: 'ProjectID', label: 'Project ID', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'ESD', label: 'ESD', alignRight: false },
  { id: 'ECD', label: 'ECD', alignRight: false },
  { id: 'HDR', label: 'Hold Date & Reason', alignRight: false },
  { id: '', label: '' },
];

const USERLIST = [
  {
    SignType: 'Sign C 8.2',
    WO: 'WO-1',
    projectID: 'BRI UAE-J1-08-23',
    status: 'In Progress',
    ESD: '8/11/2023',
    ECD: '8/11/2023',
    HDR: '',
  },
  {
    SignType: 'Sign D 4.5',
    WO: 'WO-2',
    projectID: 'BRI USA-K2-09-23',
    status: 'Completed',
    ESD: '9/15/2023',
    ECD: '9/15/2023',
    HDR: '',
    BtnStatus: 1,
  },
  {
    SignType: 'Sign A 1.0',
    WO: 'WO-3',
    projectID: 'BRI CAN-A1-07-23',
    status: 'Yet To Plan',
    ESD: '7/5/2023',
    ECD: '7/5/2023',
    HDR: '',
    BtnStatus: 1,
  },
  {
    SignType: 'Sign B 6.3',
    WO: 'WO-4',
    projectID: 'BRI UK-B2-10-23',
    status: 'On Hold',
    ESD: '10/20/2023',
    ECD: '10/20/2023',
    HDR: '',
    BtnStatus: 0,
  },
  {
    SignType: 'Sign E 2.7',
    WO: 'WO-5',
    projectID: 'BRI AU-E3-11-23',
    status: 'In Progress',
    ESD: '11/8/2023',
    ECD: '11/8/2023',
    HDR: '',
    BtnStatus: 1,
  },
  {
    SignType: 'Sign C 8.2',
    WO: 'WO-6',
    projectID: 'BRI UAE-J1-08-23',
    status: 'In Progress',
    ESD: '8/11/2023',
    ECD: '8/11/2023',
    HDR: '',
    BtnStatus: 0,
  },
  {
    SignType: 'Sign D 4.5',
    WO: 'WO-7',
    projectID: 'BRI USA-K2-09-23',
    status: 'Completed',
    ESD: '9/15/2023',
    ECD: '9/15/2023',
    HDR: 'Site On Ready 9/20/2023',
    BtnStatus: 0,
  },
  {
    SignType: 'Sign A 1.0',
    WO: 'WO-8',
    projectID: 'BRI CAN-A1-07-23',
    status: 'Yet To Plan',
    ESD: '7/5/2023',
    ECD: '7/5/2023',
    HDR: '',
    BtnStatus: 0,
  },
  {
    SignType: 'Sign B 6.3',
    WO: 'WO-9',
    projectID: 'BRI UK-B2-10-23',
    status: 'On Hold',
    ESD: '10/20/2023',
    ECD: '10/20/2023',
    HDR: 'Site On Ready 10/25/2023',
    BtnStatus: 0,
  },
  {
    SignType: 'Sign E 2.7',
    WO: 'WO-10',
    projectID: 'BRI AU-E3-11-23',
    status: 'In Progress',
    ESD: '11/8/2023',
    ECD: '11/8/2023',
    HDR: '',
    BtnStatus: 0,
  },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let filteredArray = stabilizedThis.map((el) => el[0]);

  if (query) {
    query = query.toLowerCase();
    filteredArray = filteredArray.filter((_user) => {
      // Use Object.keys to iterate over the object's own properties
      return Object.keys(_user).some((key) => _user[key].toString().toLowerCase().includes(query));
    });
  }

  return filteredArray;
}

export default function OperationActivitesPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  const [startDialogOpen, setStartDialogOpen] = useState(false);

  const startHandleDialogOpen = () => {
    setStartDialogOpen(true);
  };

  const startHandleDialogClose = () => {
    setStartDialogOpen(false);
  };

  const startHandleDialogConfirm = () => {
    console.log('Start Work');
    startHandleDialogClose();
    setStartWorkDilogBoxOpen(true);
  };
  

  const [completeDialogOpen, setCompleteDialogOpen] = useState(false);

  const completeHandleDialogOpen = () => {
    setCompleteDialogOpen(true);
  };

  const completeHandleDialogClose = () => {
    setCompleteDialogOpen(false);
  };

  const completeHandleDialogConfirm = () => {
    console.log('Complete Work');
    completeHandleDialogClose();
    setMrdCompletionDialogBoxOpen(true);
  };

  const [holdDialogOpen, setHoldDialogOpen] = useState(false);

  const holdHandleDialogOpen = () => {
    setHoldDialogOpen(true);
  };

  const holdHandleDialogClose = () => {
    setHoldDialogOpen(false);
  };

  const holdHandleDialogConfirm = () => {
    console.log('Hold Work');
    holdHandleDialogClose();    
    setMrdHoldDialogBoxOpen(true);
  };

  const [startWorkDilogBoxOpen, setStartWorkDilogBoxOpen] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  
  const employees = [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Smith' },
    { id: 3, name: 'Alice Johnson' }
  ];

  const handleOpenDialog = () => {
    setStartWorkDilogBoxOpen(true);
  };

  const handleCloseDialog = () => {
    setStartWorkDilogBoxOpen(false);
  };

  const handleEmployeeSelect = (selectedEmployees) => {
    setSelectedEmployees(selectedEmployees);
  };


  const [completeWorkDilogBoxOpen, setCompleteWorkDilogBoxOpen] = useState(false);

  const completeHandleOpenDialog = () => {
    setCompleteWorkDilogBoxOpen(true);
  };

  const completeHandleCloseDialog = () => {
    setCompleteWorkDilogBoxOpen(false);
  };

  const completeHandleConfirm  = () => {
    setCompleteWorkDilogBoxOpen(false);
    setMrdCompletionDialogBoxOpen(true);
  };

  const [holdWorkDilogBoxOpen, setHoldWorkDilogBoxOpen] = useState(false);

  const holdHandleOpenDialog = () => {
    setHoldWorkDilogBoxOpen(true);
  };

  const holdHandleCloseDialog = () => {
    setHoldWorkDilogBoxOpen(false);
  };

  const holdHandleConfirm  = () => {
    setHoldWorkDilogBoxOpen(false);
    setMrdHoldDialogBoxOpen(true);
  };

  const [mrdDilogBoxOpen, setMrdDilogBoxOpen] = useState(false);

  const mrdHandleOpenDialog = () => {
    setMrdDilogBoxOpen(true);
  };

  const mrdHandleCloseDialog = () => {
    setMrdDilogBoxOpen(false);
  };

  const [MrdCompletionDialogBoxOpen, setMrdCompletionDialogBoxOpen] = useState(false);

  const CompletionHandleOpenDialog = () => {
    setMrdCompletionDialogBoxOpen(true);
  };

  const CompletionHandleCloseDialog = () => {
    setMrdCompletionDialogBoxOpen(false);
  };


  const [MrdHoldDialogBoxOpen, setMrdHoldDialogBoxOpen] = useState(false);

  const HoldHandleOpenDialog = () => {
    setMrdHoldDialogBoxOpen(true);
  };

  const HoldHandleCloseDialog = () => {
    setMrdHoldDialogBoxOpen(false);
  };

  const linknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
    color: 'rgb(33, 43, 54)',
  };

  const actlinknav = {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontFamily: 'Public Sans, sans-serif',
    fontWeight: '400',
    textDecoration: 'none',
  };

  return (
    <>
      <Helmet>
        <title> Operation Activites | BRI Middleware </title>
      </Helmet>

      <Container maxWidth="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <div>
            <Typography variant="h4" gutterBottom>
              Operation Activites
            </Typography>

            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <Iconify icon="ic:baseline-circle" width="6px" borderRadius="50%" color="rgb(145, 158, 171)" />
              }
            >
              <Link style={linknav} to="/dashboard">
                Dashboard
              </Link>
              <Link style={linknav} to="/operationActivites">
                Operation Activites
              </Link>
              <Typography style={actlinknav} color="text.secondary">
                List
              </Typography>
            </Breadcrumbs>
          </div>
          <div>
            {/* <Button to="/createUser" color='primary' variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} component={Link}>
              New User
            </Button> */}
          </div>
        </Stack>

        <Card>
          <OperationActivitesToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <WorkOrderHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const { id, SignType, WO, projectID, status, ESD, ECD, HDR, BtnStatus } = row;
                    const serialNumber = page * rowsPerPage + index + 1;
                    return (
                      <TableRow hover key={WO} tabIndex={-1}>
                        <TableCell padding="checkbox">
                          <Typography variant="subtitle2" noWrap paddingX={2}>
                            {serialNumber}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none" style={{ Width: '10px !important' }}>
                          <Typography variant="subtitle2">{SignType}</Typography>
                        </TableCell>
                        <TableCell align="left">{WO}</TableCell>
                        <TableCell align="left">{projectID}</TableCell>
                        <TableCell align="left">
                          {' '}
                          <StatusLabel status={status} />
                        </TableCell>
                        <TableCell align="left"> {ESD} </TableCell>
                        <TableCell align="left">{ECD}</TableCell>
                        <TableCell align="left">{HDR}</TableCell>
                        <TableCell align="right">
                          <Grid container justifyContent="flex-end" alignItems="center">
                            {BtnStatus === 0 ? (
                              <Button
                                variant="contained"
                                size="small"
                                color="success"
                                startIcon={<Iconify icon={'eva:play-circle-outline'} />}
                                onClick={startHandleDialogOpen}
                              >
                                Start
                              </Button>
                            ) : (
                              <ButtonGroup>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="success"
                                  startIcon={<Iconify icon={'eva:pause-circle-outline'} />}
                                  onClick={completeHandleDialogOpen}
                                >
                                  Complete
                                </Button>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="error"
                                  startIcon={<Iconify icon={'eva:stop-circle-outline'} />}
                                  onClick={holdHandleDialogOpen}
                                >
                                  Hold
                                </Button>
                              </ButtonGroup>
                            )}

                            <Button variant="contained" size="small" sx={{ ml: 1 }} onClick={mrdHandleOpenDialog}>
                              MRD
                            </Button>
                            <Tooltip title="File" placement="top" arrow>
                              <NavLink to={'https://brisigns-my.sharepoint.com/:b:/p/akash_r/EeFQoRILR45AhcmTbem0u7IBAjj29-PGDDP1K6B-fBx7LA?e=xtOcfR'} target='blank'>
                                <IconButton aria-label="fingerprint" color="secondary">
                                  <Iconify icon={'eva:file-text-outline'} />
                                </IconButton>
                              </NavLink>
                            </Tooltip>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={filteredUsers.length <= 5 ? [0] : [5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

    
        </Card>
      </Container>
      
      <DialogBox
        open={startDialogOpen}
        onClose={startHandleDialogClose}
        onConfirm={startHandleDialogConfirm}
        btnColor={'success'}
        dContent={"Are you sure you're going to start work?"}
        dTitle={"Confirm Start Work"}
        btnText={'Start Work'}
      />  

      <DialogBox
        open={completeDialogOpen}
        onClose={completeHandleDialogClose}
        onConfirm={completeHandleDialogConfirm}
        btnColor={'success'}
        dContent={"Are you sure you're going to complete work?"}
        dTitle={"Confirm Complete Work"}
        btnText={'Complete Work'}
      />

      <DialogBox
        open={holdDialogOpen}
        onClose={holdHandleDialogClose}
        onConfirm={holdHandleDialogConfirm}
        btnColor={'error'}
        dContent={"Are you sure you're going to hold work?"}
        dTitle={"Confirm hold Work"}
        btnText={'Hold Work'}
      /> 

      <StartWorkDialogBox 
        open={startWorkDilogBoxOpen}
        onClose={handleCloseDialog}
        employees={employees}
        onConfirm={handleEmployeeSelect}
      />

      <PauseWorkDialogBox 
        open={completeWorkDilogBoxOpen}
        onClose={completeHandleCloseDialog}
        onConfirm={completeHandleConfirm}
      />

      <HoldWorkDialogBox 
        open={holdWorkDilogBoxOpen}
        onClose={holdHandleCloseDialog}
        onConfirm={holdHandleConfirm}
      />

      <MrdDialogBox
        open={mrdDilogBoxOpen}
        onClose={mrdHandleCloseDialog}
      />

      <MrdCompletionDialogBox 
        open={MrdCompletionDialogBoxOpen}
        onClose={CompletionHandleCloseDialog}
      />

      <MrdHoldDialogBox 
        open={MrdHoldDialogBoxOpen}
        onClose={HoldHandleCloseDialog}
      />

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'success.dark' }}>
          <Iconify icon={'eva:paper-plane-fill'} sx={{ mr: 2 }} />
          Plan
        </MenuItem>

        <MenuItem sx={{ color: 'warning.dark' }}>
          <Iconify icon={'eva:pause-circle-outline'} sx={{ mr: 2 }} />
          Hold
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:close-circle-outline'} sx={{ mr: 2 }} />
          Cancel
        </MenuItem>
      </Popover>
    </>
  );
}
