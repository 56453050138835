import React, { useState, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const DialogBox = ({ open, onClose, onConfirm, btnColor, dContent, dTitle, btnText }) => {
    return (
      <Dialog open={open}>
        <DialogTitle>{dTitle}</DialogTitle>
        <DialogContent>
         {dContent}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} style={{borderColor:'black', color:'black'}} size='small'>
            Cancel
          </Button>
          <Button variant="contained" onClick={onConfirm} color={btnColor} size='small'>
            {btnText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  export default DialogBox;