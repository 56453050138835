import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, NavLink } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  styled,
  tableCellClasses,
  Card,
  ButtonGroup,
  Grid,
  TextField,
  Checkbox,
  Typography,
  Divider,
  IconButton,
  Autocomplete,
  Tooltip,
  
} from '@mui/material';
import Iconify from '../iconify';
import Label from '../label';
import { APICONFIG } from '../../config/apiConfig';



const RandomQCDialogBox = ({ open, onClose, dataPop, onConfirm }) => {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    handleClose();
  };

  const [mrdDetails, setMrdDetails] = useState(dataPop);
  const [qcData, setQcData] = useState({});
  const [qcParams, setQcParams] = useState([]);
  const {
    handleSubmit: handleSubmitMachinery,
    register: registerMachinery,
    formState: { errors: errorsMachinery },
    reset: resetMachinery,
  } = useForm();

  // Define the second form instance
  const {
    handleSubmit: handleSubmitScrap,
    register: registerScrap,
    formState: { errors: errorsScrap },
    reset: resetScrap,
  } = useForm();



  

  useEffect(() => {
    console.log(dataPop.QcParams,' dataPop.QcParams');
    if (open) {
      // console.log(dataPop,' dataPop');
      setQcParams(dataPop.QcParams);
      setMrdDetails(dataPop);
       setQcData((dataPop));
    }else{
      setMrdDetails({});
      setQcData({QcParams:[]});
      setQcParams(dataPop.QcParams);
    }
  }, [open]);






  const dialogStyle = {
    borderRadius: '12px',
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      fontWeight: 'bold',
    },
  }));

  return (
    <Dialog open={open} maxWidth="lg" fullWidth PaperProps={{ style: dialogStyle }}>
      <DialogTitle>
        Random QC : <span style={{ color: 'GrayText', fontWeight: 700 }}>{mrdDetails.QcId} </span>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" gap={2} columns={12}>
          <Grid item xs={12} md={12} xl={5.9}>
            <Divider sx={{ mb: 2 }}>
              <Label color="info">Random QC</Label>
            </Divider>
            <Grid mb={5}>
          <Card>
            <Grid>
              <Typography sx={{ pt: 2, pb: 2, pl: 2 }}>
                <Label color="info">QC DETAILS</Label>
              </Typography>
              <Grid mt={1}>
                <TableContainer component={Paper}>
                  <Table sx={{ maxWidth: '100%' }} size="small" aria-label="caption table">
                    <TableBody>
                      <TableRow>
                        <StyledTableCell2 align="left" width={150}>
                          Work Order:
                        </StyledTableCell2>
                        <TableCell align="left" colSpan={1}>
                      { mrdDetails.WorkOrderId}
                          {/* {row.workOrderT} */}
                        </TableCell>
                        {/* <StyledTableCell2 align="left">QC ON:</StyledTableCell2>


<TableCell align="left">{mrdDetails.CreatedOn}</TableCell> */}

                      </TableRow>
                      <TableRow>
                        <StyledTableCell2 align="left" width={150}>Remarks:</StyledTableCell2>
                        <TableCell align="left">{mrdDetails.Remarks}</TableCell>
                        {/* <StyledTableCell2 align="left" width={150}>Intiater</StyledTableCell2>
                        <TableCell align="left">{}</TableCell> */}
                      
                      </TableRow>
                      <TableRow>
                     
                        <StyledTableCell2 align="left">Product Image:</StyledTableCell2>
                        <TableCell align="left">
                        <Tooltip title="File" placement="top" arrow>
                            <NavLink to={`${APICONFIG.HOST}/api/qc/file_render?url=${mrdDetails.ProductImg}`} target='blank'>
                              <IconButton aria-label="fingerprint" color="secondary">
                                <Iconify icon={'eva:file-text-outline'} />
                              </IconButton>
                            </NavLink>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                  
                    </TableBody>
                  </Table>
                </TableContainer>

              </Grid>
            </Grid>
          </Card>
        </Grid>
            <Card sx={{ mb: 2 }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Department</StyledTableCell>
                      <StyledTableCell align="left"/>
                      <StyledTableCell align="left"/>
                      <StyledTableCell align="left"/>
                      <StyledTableCell align="left"/>
                      <StyledTableCell align="left"/>
                      <StyledTableCell align="left"/>

                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    
                      <TableRow key={qcData.RandomQcId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledTableCell component="th" scope="row">
                          {qcData.DepartmentId} 
                        </StyledTableCell>
                        {qcParams.map((q) => (
                        // eslint-disable-next-line react/jsx-key
                        <StyledTableCell align="left"><Label color={q.paramValue==="Yes"?"success":"error"}>{q.param}</Label>
                        <br/><b>{q.param==='Prior Department QC Problems'?((q.previousDepartmentId!=='' && q.previousDepartmentId!==0)?q.previousDepartmentId:''):''}</b>
                        {q.paramValue==="Yes"?"":   <><Tooltip title="File" placement="top" arrow>
                              <NavLink to={`${APICONFIG.HOST}/api/qc/file_render?url=${q.img}`} target='blank'>
                                <IconButton aria-label="fingerprint" color="secondary">
                                  <Iconify icon={'eva:file-text-outline'} />
                                </IconButton>
                              </NavLink>
                            </Tooltip><Tooltip title={q.remarks} placement="top" arrow>
                              
                                    <Iconify icon={'la:comments-solid'} />
                                  
                              </Tooltip></>
                          }
                        </StyledTableCell>))}
                       
                      </TableRow>
                    
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={12} xl={5.9}>
            <Divider sx={{ mb: 2 }}>
              <Label color="info">Material Requisition Document </Label>
            </Divider>
            <Card sx={{ mb: 2 }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ width: 2 }}>Sl.No</StyledTableCell>
                    <StyledTableCell align="left">Material Details</StyledTableCell>
                    <StyledTableCell align="center" sx={{ width: 30 }}>
                      Quantity
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <StyledTableCell colSpan={3}>Loading...</StyledTableCell>
                    </TableRow>
                  ) : data.length === 0 ? (
                    <TableRow>
                      <StyledTableCell colSpan={3} align="center">
                        No data found
                      </StyledTableCell>
                    </TableRow>
                  ) : (
                    data.map((row, index) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.materialDetails}</StyledTableCell>
                        <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Card>
          </Grid> */}
        </Grid>
        {/* <Grid container justifyContent="center" gap={2} columns={12}>
          <Grid item xs={12} md={12} xl={5.9}>
            <Divider sx={{ mb: 2 }}>
              <Label color="info">Machinary</Label>
            </Divider>
            <Card sx={{ mb: 2 }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ width: 92 }}>Sl.No</StyledTableCell>
                      <StyledTableCell align="left">Machinery</StyledTableCell>
                      <StyledTableCell align="center">Time</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsMachinery.length === 0 ? (
                      <TableRow>
                        <StyledTableCell colSpan={3}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="body2">No Machinery Selected</Typography>
                          </Paper>
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      rowsMachinery.map((row, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <StyledTableCell component="th" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.machine}</StyledTableCell>
                          <StyledTableCell align="center" sx={{ width: 100 }}>
                            {row.time}
                          </StyledTableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} xl={5.9}>
            <Divider sx={{ mb: 2 }}>
              <Label color="info">Scrap</Label>
            </Divider>
            <Card sx={{ mb: 2 }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ width: 92 }}>Sl.No</StyledTableCell>
                      <StyledTableCell align="left">Scrap Item</StyledTableCell>
                      <StyledTableCell align="center">Quantity</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsScrap.length === 0 ? (
                      <TableRow>
                        <StyledTableCell colSpan={3}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="body2">No Scrap Added</Typography>
                          </Paper>
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      rowsScrap.map((row, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <StyledTableCell component="th" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.scrapItem}</StyledTableCell>
                          <StyledTableCell align="center" sx={{ width: 100 }}>
                            {row.quantity}
                          </StyledTableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid> */}
      </DialogContent>
      <DialogActions sx={{ pr: 3, pb: 2 }}>
        <Button onClick={handleClose} variant="outlined" style={{ borderColor: 'black', color: 'black' }} size="small">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RandomQCDialogBox;
