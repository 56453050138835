import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  styled,
  tableCellClasses,
  Card,
  ButtonGroup,
  Grid,
  TextField,
  Checkbox,
  Typography,
  Divider,
  IconButton,
  Autocomplete,
} from '@mui/material';
import Iconify from '../iconify';
import Label from '../label';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(empId, empName, time) {
  return { empId, empName, time };
}

const rows = [createData('E0607', 'JOSEPH JAMES', 10), createData('E1009', 'ABISH ANTONY XAVIEM', 10)];

const dummyData = [
  { orderNumber: 1, materialDetails: 'Material A', materialSelected: true, quantity: 10, updateQuantity: 8 },
  { orderNumber: 2, materialDetails: 'Material B', materialSelected: false, quantity: 5, updateQuantity: 4 },
  { orderNumber: 3, materialDetails: 'Material C', materialSelected: true, quantity: 1, updateQuantity: 2 },
  { orderNumber: 4, materialDetails: 'Material D', materialSelected: false, quantity: 3, updateQuantity: 5 },
];

const machineryOptions = [
  { label: 'Machine A', value: '1' },
  { label: 'Machine B', value: '2' },
  { label: 'Machine C', value: '3' },
  // Add more machinery options as needed
];

const scrapOptions = [
  { label: 'Scrap A', value: '1' },
  { label: 'Scrap B', value: '2' },
  { label: 'Scrap C', value: '3' },
  // Add more machinery options as needed
];

const MrdHoldDialogBox = ({ open, onClose, employees, onConfirm }) => {

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    handleClose();
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowsMachinery, setRowsMachinery] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [rowsScrap, setRowsScrap] = useState([]);
  const [selectedScrap, setSelectedScrap] = useState(null);

  const {
    handleSubmit: handleSubmitMachinery,
    register: registerMachinery,
    formState: { errors: errorsMachinery },
    reset: resetMachinery,
  } = useForm();

  // Define the second form instance
  const {
    handleSubmit: handleSubmitScrap,
    register: registerScrap,
    formState: { errors: errorsScrap },
    reset: resetScrap,
  } = useForm();

  const handleAddRowMachinery = (data) => {
    const formDataWithLabel = {
      machine: data.machine,
      time: data.time,
    };
    setRowsMachinery([...rowsMachinery, formDataWithLabel]);
    resetMachinery();
    setSelectedMachine(null);
    console.log(rowsMachinery);
  };

  const availableMachineryOptions = machineryOptions.filter(
    (option) => !rowsMachinery.some((row) => row.machine === option.label)
  );

  const handleDeleteRowMachinery = (index) => {
    const updatedRows = [...rowsMachinery];
    updatedRows.splice(index, 1);
    setRowsMachinery(updatedRows); // Changed state variable name
  };


  const handleAddRowScrap = (data) => {
    console.log(data)
    const formDataWithLabel = {
      scrapItem: data.scrapItem,
      quantity: data.quantity,
    };
    setRowsScrap([...rowsScrap, formDataWithLabel]);
    resetScrap();
    setSelectedScrap(null);
    console.log(rowsScrap);
  };

  const availableScrapOptions = scrapOptions.filter(
    (option) => !rowsScrap.some((row) => row.scrapItem === option.label)
  );

  const handleDeleteRowScrap = (index) => {
    const updatedRows = [...rowsScrap];
    updatedRows.splice(index, 1);
    setRowsScrap(updatedRows); // Changed state variable name
  };


  const fetchData = () => {
    setLoading(true);
    // Fetch data from your API
    fetch('./api/data')
      .then((response) => response.json())
      .then((data) => {
        setData(data); // Update the data state with the fetched data
        setLoading(false); // Set loading to false
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false even in case of an error
        setData(dummyData);
      });
  };

  useEffect(() => {
    if (open && data.length === 0) {
      fetchData();
    }

    fetch('./api/dataiEndpoint')
      .then((response) => response.json())
      .then((data) => setRowsMachinery(data))
      .catch((error) => console.error('Error fetching data:', error));
  }, [open]);

  const handleCheckboxChange = (index) => {
    const updatedData = [...data];
    updatedData[index].materialSelected = !updatedData[index].materialSelected;
    setData(updatedData);
  };

  const handleQuantityChange = (index, value) => {
    const updatedData = [...data];
    updatedData[index].quantity = value;
    setData(updatedData);
  };

  const handleSave = () => {
    // Perform the API submission with the updated data here
    // For example, you can use the fetch function to send the data to the API

    // After a successful API call, you can close the dialog
    handleClose();
  };

  const dialogStyle = {
    borderRadius: '12px',
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth PaperProps={{ style: dialogStyle }}>
      <DialogTitle>Workorder Hold </DialogTitle>
      <DialogContent>
        <Divider sx={{ mb: 2 }}>
          <Label color="info">Timesheet</Label>
        </Divider>
        <Card sx={{ mb: 2 }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ width: 100 }}>EMP ID</StyledTableCell>
                  <StyledTableCell align="left">EMP Name</StyledTableCell>
                  <StyledTableCell align="center">Time</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.empId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell component="th" scope="row">
                      {row.empId}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.empName}</StyledTableCell>
                    <StyledTableCell align="center">{row.time}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Divider sx={{ mb: 2 }}>
          <Label color="info">Material Requisition Document </Label>
        </Divider>

        <Card sx={{ mb: 2 }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ width: 2 }}>Sl.No</StyledTableCell>
                <StyledTableCell align="left">Material Details</StyledTableCell>
                <StyledTableCell align="center" sx={{ width: 30 }}>
                  Quantity
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: 128 }}>
                  Used Quantity
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ width: 160 }}>
                  Select Material
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <StyledTableCell colSpan={3}>Loading...</StyledTableCell>
                </TableRow>
              ) : data.length === 0 ? (
                <TableRow>
                  <StyledTableCell colSpan={3} align="center">
                    No data found
                  </StyledTableCell>
                </TableRow>
              ) : (
                data.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.materialDetails}</StyledTableCell>
                    <StyledTableCell align="center">{row.quantity}</StyledTableCell>
                    <StyledTableCell align="center" sx={{ padding: '2px 14px' }}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={row.quantity}
                        disabled={!row.materialSelected}
                        onChange={(e) => handleQuantityChange(index, e.target.value)}
                        style={{ padding: '5px 14px !important' }}
                      />
                    </StyledTableCell>
                    <StyledTableCell style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        sx={{ p: 0 }}
                        checked={row.materialSelected}
                        onChange={() => handleCheckboxChange(index)}
                      />

                      <Typography variant="body2">{row.materialSelected ? 'Selected' : 'Not Selected'}</Typography>
                    </StyledTableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Card>

        <Grid container justifyContent="center" gap={2} columns={12}>
          <Grid item xs={12} md={12} xl={5.9}>
            <Divider sx={{ mb: 2 }}>
              <Label color="info">Machinary</Label>
            </Divider>
            <Card sx={{ mb: 2 }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ width: 92 }}>Sl.No</StyledTableCell>
                      <StyledTableCell align="left">Machinery</StyledTableCell>
                      <StyledTableCell align="center">Time</StyledTableCell>
                      <StyledTableCell align="center">#</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsMachinery.length === 0 ? (
                      <TableRow>
                        <StyledTableCell colSpan={4}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="body2">No Machinery Selected</Typography>
                          </Paper>
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      rowsMachinery.map((row, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <StyledTableCell component="th" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.machine}</StyledTableCell>
                          <StyledTableCell align="center" sx={{ width: 100 }}>
                            {row.time}
                          </StyledTableCell>
                          <StyledTableCell align="center" sx={{ width: 10 }}>
                            <IconButton
                              aria-label="fingerprint"
                              color="secondary"
                              size="small"
                              onClick={() => handleDeleteRowMachinery(index)}
                            >
                              <Iconify icon={'eva:trash-2-outline'} />
                            </IconButton>
                          </StyledTableCell>
                        </TableRow>
                      ))
                    )}

                    <TableRow>
                      <StyledTableCell colSpan={4}>
                        <form onSubmit={handleSubmitMachinery(handleAddRowMachinery)}>
                          <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item xs={6} md={9.7} xl={7}>
                              <Autocomplete
                                size="small"
                                value={selectedMachine}
                                onChange={(event, newValue) => setSelectedMachine(newValue)}
                                options={availableMachineryOptions}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Machinery"
                                    fullWidth
                                    {...registerMachinery('machine', { required: 'Machine is required' })}
                                    error={!!errorsMachinery.machine}
                                    helperText={errorsMachinery.machine?.message}
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <li key={option} {...props} style={{ fontSize: '0.875rem' }}>
                                    {option.label}
                                  </li>
                                )}
                              />
                            </Grid>
                            <Grid item xs={4} md={0.9} xl={3}>
                            <TextField
                                size="small"
                                {...registerMachinery('time', { required: 'Time is required', pattern: {
                                  value: /^\d+$/, // Regex to allow only numeric input
                                  message: 'Please enter a valid quantity',
                                },})}
                                label="Time"
                                error={!!errorsMachinery.time}
                                helperText={errorsMachinery.time ? errorsMachinery.time.message : ''}
                                inputProps={{
                                  inputMode: 'numeric',
                                  pattern: '[0-9]*', // For mobile devices that don't support inputMode numeric
                                  style: { textAlign: 'right' }, // Optional: Align text to the right
                                }}
                              />
                            </Grid>
                            <Grid item xs={0.5} sx={{ pr: 4.5 }}>
                              <IconButton
                                aria-label="fingerprint"
                                color="primary"
                                size="large"
                                onClick={handleSubmitMachinery(handleAddRowMachinery)}
                              >
                                <Iconify fontSize="large" icon={'eva:plus-square-outline'} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </form>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} xl={5.9}>
            <Divider sx={{ mb: 2 }}>
              <Label color="info">Scrap</Label>
            </Divider>
            <Card sx={{ mb: 2 }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ width: 92 }}>Sl.No</StyledTableCell>
                      <StyledTableCell align="left">Scrap Item</StyledTableCell>
                      <StyledTableCell align="center">Quantity</StyledTableCell>
                      <StyledTableCell align="center">#</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsScrap.length === 0 ? (
                      <TableRow>
                        <StyledTableCell colSpan={4}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="body2">No Scrap Added</Typography>
                          </Paper>
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      rowsScrap.map((row, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <StyledTableCell component="th" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.scrapItem}</StyledTableCell>
                          <StyledTableCell align="center" sx={{ width: 100 }}>
                            {row.quantity}
                          </StyledTableCell>
                          <StyledTableCell align="center" sx={{ width: 10 }}>
                            <IconButton
                              aria-label="fingerprint"
                              color="secondary"
                              size="small"
                              onClick={() => handleDeleteRowScrap(index)}
                            >
                              <Iconify icon={'eva:trash-2-outline'} />
                            </IconButton>
                          </StyledTableCell>
                        </TableRow>
                      ))
                    )}

                    <TableRow>
                      <StyledTableCell colSpan={4}>
                        <form onSubmit={handleSubmitScrap(handleAddRowScrap)}>
                          <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item xs={6} md={9.7} xl={8}>
                            <TextField
                                size="small"
                                label="Scrap Item"
                                fullWidth
                                {...registerScrap('scrapItem', { required: 'Scrap Item is required' })}
                                error={!!errorsScrap.scrapItem}
                                helperText={errorsScrap.scrapItem ? errorsScrap.scrapItem.message : ''}
                                value={selectedScrap} // Add value prop if selectedScrap is needed
                              />
                            </Grid>
                            <Grid item xs={4} md={0.9} xl={2}>
                            <TextField
                                size="small"
                                {...registerScrap('quantity', {
                                  required: 'Quantity is required',
                                  pattern: {
                                    value: /^\d+$/, // Regex to allow only numeric input
                                    message: 'Please enter a valid quantity',
                                  },
                                })}
                                label="Quantity"
                                error={!!errorsScrap.quantity}
                                helperText={errorsScrap.quantity ? errorsScrap.quantity.message : ''}
                                inputProps={{
                                  inputMode: 'numeric',
                                  pattern: '[0-9]*', // For mobile devices that don't support inputMode numeric
                                  style: { textAlign: 'right' }, // Optional: Align text to the right
                                }}
                              />
                            </Grid>
                            <Grid item xs={0.5} sx={{ pr: 4.5 }}>
                              <IconButton
                                aria-label="fingerprint"
                                color="primary"
                                size="large"
                                onClick={handleSubmitScrap(handleAddRowScrap)}
                              >
                                <Iconify fontSize="large" icon={'eva:plus-square-outline'} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </form>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pr: 3, pb: 2 }}>
        <Button onClick={handleClose} variant="outlined" style={{ borderColor: 'black', color: 'black' }} size="small">
          Cancel
        </Button>
        <Button variant="contained" color="error" size="small" onClick={handleSave}>
          {' '}
          Hold Work
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MrdHoldDialogBox;


