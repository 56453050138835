export const APICONFIG = Object.freeze({
    HOST:"https://api-middleware.app-brisigns.com",
    // HOST:"http://localhost:3000",
    API_PREFIX:"api",
    SECRETKEY: "ZVJhZEFkbWluU2VjcmV0",
    rowPerPage:[{label:'5',value:5},{label:'10',value:10}, {label:'15',value:15},{label:"ALL",value:10000}],
    USER:{
        ROOT:"auth",
        CREATE:"user_creation",
        UPDATE:"user_creation",
        LOGIN:"login",
        LOGOUT:"logout",
        RESET_LINK:"resetLink",
        RESET_LINK_VALIDATION:"resetLinkValidation",
        RESET_PASSWORD:"reset_password"
    },
    QC:{
        ROOT:"qc",
        RANDOM_QC_WO_LIST:"random_qc_wo_list",
        FINAL_QC_WO_LIST:"final_qc_wo_list",
        RANDOM_QC_PARAM_LIST:"random_qc_param_list",
        FINAL_QC_PARAM_LIST:"final_qc_param_list",
        LOAD_DEPARTMENT_LIST:"department_list",
        RANDOM_QC_FORM_SUBMIT:'random_qc_submit',
        FINAL_QC_FORM_SUBMIT:'final_qc_submit',
        RANDOM_QC_HISTORY:'get_random_qc',
        GET_FINAL_QC:'final_qc_list',
        GET_RANDOM_QC:'random_qc_list'
    }
});