import React, { useState, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Autocomplete, TextField } from '@mui/material';
import { toast } from 'react-hot-toast';

const StartWorkDialogBox = ({ open, onClose, employees, onConfirm}) => {
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const handleClose = () => {
    setSelectedEmployees([]);
    onClose();
  };

  const handleConfirm = () => {
    toast.success('Successfully created!');
    handleClose();
    // Assuming you have an API endpoint to send the selected employees
    // Replace 'apiEndpoint' with your actual API endpoint URL
    const apiEndpoint = 'https://example.com/api/employees';

    // Send a POST request to the API with the selected employees
    fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedEmployees),
    })
      .then((response) => {
        if (response.ok) {
          // Handle successful API response here
          console.log('API Response:', response);
          onConfirm(selectedEmployees);
          handleClose();
        } else {
          // Handle API error here
          console.error('API Error:', response);
        }
      })
      .catch((error) => {
        // Handle network error here
        console.error('Network Error:', error);
      });
  };
  
    return (
      <Dialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>Select Employees to start Work</DialogTitle>
        <DialogContent>
          <Autocomplete sx={{pt:2}}
            multiple
            options={employees}
            getOptionLabel={(employee) => `${employee.id} - ${employee.name}`}
            value={selectedEmployees}
            onChange={(_, newValue) => setSelectedEmployees(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Select employee(s)" variant="outlined" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ borderColor: 'black', color: 'black' }} size="small">
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            size="small"
            disabled={selectedEmployees.length === 0}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  export default StartWorkDialogBox;