import * as React from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  TextField,
  FormControl,
  InputLabel,
  Autocomplete,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 75,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
  overflowX: 'auto',
  flexWrap: 'nowrap', // Ensure items stay in a single line

  // Media query for screens less than 1675px wide
  '@media (max-width: 1675px)': {
    '&::-webkit-scrollbar': {
      height: '6px', // Customize scrollbar height as needed
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[400], // Customize scrollbar color as needed
      borderRadius: '6px', // Round the scrollbar thumb
    },
  },
}));

const StyledRoot2 = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'stat',
  padding: '0px !important',
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  minWidth: 240,
  height: 55,
  marginRight: 20,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const StyleLoaction = styled(Select)(({ theme }) => ({
  width: 240,
  height: 55,
  marginRight: 20,
  fontSize: '0.825rem',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const StyleDate = styled(DatePicker)(({ theme }) => ({
  width: 240,
  minWidth: 240,
  fontSize: '0.825rem',
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const StyleMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: '6px 3px',
  margin: 3,
  borderRadius: '6px',
}));

const MenuProps = {
  PaperProps: {
    style: {
      width: 240,
      paddingLeft: 8,
      paddingRight: 8,
      outline: 0,
      backdropFilter: 'blur(20px)',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      color: 'rgb(33, 43, 54)',
    },
  },
};

const whLocation = ['BRI HQ', 'BRI UAQ', 'PRS SAU', 'PRS SAJ'];
const pId = ['BRI UAE-J1-08-23', 'BRI UAE-J1-09-23', 'BRI UAE-J1-10-23', 'BRI UAE-J1-11-23'];
const pType = ['Projects', 'Retail'];

// ----------------------------------------------------------------------

OperationActivitesToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  filterPid: PropTypes.string,
  onFilterPid: PropTypes.func,
};

export default function OperationActivitesToolbar({ numSelected, filterName, onFilterName, filterPid, onFilterPid }) {
  const [location, setLocation] = React.useState([]);
  const [projectId, setProjectId] = React.useState([]);  
  const [projectType, setProjectType] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(selectedDate);
  };

  const handleChangeLocation = (event) => {
    const {
      target: { value },
    } = event;
    setLocation(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangeProjectId = (event) => {
    const {
      target: { value },
    } = event;
    setProjectId(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangeProjectType = (event) => {
    const {
      target: { value },
    } = event;
    setProjectType(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <StyledRoot2>
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <StyledSearch
            value={filterName}
            size="small"
            onChange={onFilterName}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
        )}

        <div>
          <FormControl sx={{ mr: 2.5, width: 250 }}>
            <InputLabel id="demo-multiple-checkbox-label">Location</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={location}
              onChange={handleChangeLocation}
              input={<OutlinedInput label="Location" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {whLocation.map((name) => (
                <StyleMenuItem key={name} value={name}>
                  <Checkbox style={{ padding: '4px', marginRight: '6px' }} checked={location.indexOf(name) > -1} />
                  <ListItemText
                    primary={
                      <span className="custom-text" style={{ fontSize: '0.875rem' }}>
                        {name}
                      </span>
                    }
                  />
                </StyleMenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyleDate
            label="Released Date"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(params) => (
              <StyleDate
                style={{
                  padding: '4px', // Adjust the padding as needed
                  fontSize: '14px', // Adjust the font size as needed
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      </StyledRoot2>
    </StyledRoot>
  );
}
