import React, { useState, useCallback } from 'react';
import { Button, Autocomplete, TextField, Grid, Dialog, DialogActions, DialogContent, ButtonGroup, DialogTitle, TextareaAutosize,CircularProgress } from '@mui/material';

const WoopDialogBox = ({ open, onClose, onConfirm1, departmentList, btnColor1, priorDepartmentEnable, dContent, dTitle, handleFileInputChange,handleTextBoxChange,setpreviousDepartment,remarksError,fileError,prevDepError,loading }) => (

  <Dialog open={open}>
    <DialogTitle>{dTitle}</DialogTitle>
    <hr />
    <DialogContent>{dContent}</DialogContent>
    <DialogContent>
      {priorDepartmentEnable?
      <><Grid item xs={12} md={6} display={false}> {/* Full width on mobile */}
          <Autocomplete
            size="small"
            options={departmentList}

            onChange={(_e, v) => {

              if (v != null) {
                setpreviousDepartment(v.value);
              } else {
                setpreviousDepartment(0);
              }

            } }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Prior Department QC Problems"
                fullWidth />
            )}
            renderOption={(props, option) => (
              <li key={option} {...props} style={{ fontSize: '0.875rem' }}>
                {option.label}
              </li>
            )} />
        </Grid>{prevDepError ?<span style={{color:'red'}}>Please select department.</span>:''}</>
      :''}
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} > {/* Full width on mobile */}
          <TextareaAutosize minRows={5} aria-label="empty textarea" onChange={handleTextBoxChange} placeholder="Comments" />
        {remarksError?<><br /><span style={{ color: 'red' }}>Please fill out remarks.</span></>:''}
        </Grid>
        <Grid item xs={12} md={6}>
          <label htmlFor="upload-photo">
            <input
              style={{ display: 'none' }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              onChange={handleFileInputChange}
              accept="image/x-png,image/gif,image/jpeg"
            />
          {fileError?<span style={{color:'red'}}>Please select product image.</span>:''}
            <Button color="secondary" variant="contained" component="span">
              Product Image 
            </Button>
          </label>
        </Grid>
      </Grid>

    </DialogContent>
    <DialogActions>

      <Button variant="outlined" onClick={onClose} style={{ borderColor: 'black', color: 'black' }} size="small">
        Cancel
      </Button>
      <Button variant="contained" onClick={onConfirm1} color={btnColor1} size="small">
        Save {loading? <CircularProgress />:''}
      </Button>

    </DialogActions>
  </Dialog>
);

export default WoopDialogBox;
