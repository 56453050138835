import React from 'react';
import { sentenceCase } from 'change-case';
import Label from '../label';

const StatusLabel = ({ status }) => {
  const getColorAndText = () => {
    switch (status) {
      case 'Initiated':
        return { color: 'secondary', text: 'Initiated' };
      case 'In Progress':
        return { color: 'warning', text: 'In Progress' };
      case 'Completed':
        return { color: 'success', text: 'Completed' };
      case 'On Hold':
        return { color: 'error', text: 'On Hold' };
      default:
        return { color: 'info', text: 'Yet to Plan' };
    }
  };

  const { color, text } = getColorAndText();

  return (
    <Label color={color}>
      {sentenceCase(text)}
    </Label>
  );
};

export default StatusLabel;
