
import Cookies from 'js-cookie';
import AES from 'crypto-js/aes';
import CryptoJS from "crypto-js";
import { useLocation } from 'react-router-dom'
import { APICONFIG } from '../config/apiConfig';
import SvgColor from '../components/svg-color';


export function detectOS() {
  const platform = navigator.platform;
  if (platform.indexOf('Win') !== -1) return 'Windows';
  if (platform.indexOf('Mac') !== -1) return 'Mac OS';
  if (platform.indexOf('Linux') !== -1) return 'Linux';
  if (platform.indexOf('iPhone') !== -1) return 'iOS';
  if (platform.indexOf('Android') !== -1) return 'Android';
  if (platform.indexOf('iPad') !== -1) return 'iPad';
  return 'Unknown';
}

export function detectBrowser() {
  let sBrowser = navigator.userAgent;
  const sUsrAg = navigator.userAgent;

  // The order matters here, and this may report false positives for unlisted browsers.

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
    // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
    sBrowser = "Samsung Internet";
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
    sBrowser = "Opera";
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
  } else if (sUsrAg.indexOf("Trident") > -1) {
    sBrowser = "Microsoft Internet Explorer";
    // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
  } else if (sUsrAg.indexOf("Edge") > -1) {
    sBrowser = "Microsoft Edge";
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
  } else if (sUsrAg.indexOf("Chrome") > -1) {
    sBrowser = "Google Chrome or Chromium";
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
  } else if (sUsrAg.indexOf("Safari") > -1) {
    sBrowser = "Apple Safari";
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
  } else {
    sBrowser = "unknown";
  }
  return sBrowser;
}

export const encryptFieldUsingAES = (value) => {
  return AES.encrypt(value, APICONFIG.SECRETKEY).toString();
}

export const decryptFieldUsingAES = (value) => {
  return AES.decrypt(value, APICONFIG.SECRETKEY).toString(CryptoJS.enc.Utf8);
}

export const getEncryptedShaValue = (value) => CryptoJS.SHA256(value).toString()

export const getEncryptedMD5Value = (value) => CryptoJS.MD5(value).toString()

export const encryptJson = (data) => CryptoJS.AES.encrypt(JSON.stringify(data), APICONFIG.SECRETKEY,
  {
    keySize: 128 / 8,
    iv: APICONFIG.SECRETKEY,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  }).toString();

export const decryptJson = (data) => JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(data, APICONFIG.SECRETKEY,
  {
    keySize: 128 / 8,
    iv: APICONFIG.SECRETKEY,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })))

export const validateLogin = () => {
  try {
    // const auth = Cookies.get("auth");
    const auth = localStorage.getItem("auth");
    if (auth != null) {
      // console.log(auth,'auth');
      const decrypt = decryptFieldUsingAES(auth);
      const authData = JSON.parse(decrypt);
      // console.log(authData, ' validatelogin');
      if (authData.remember === true || authData.remember === "true") {
        return true;
      }
    }
    Cookies.remove("auth");
    Cookies.remove("authPermission");
    return false;
  } catch (e) {
    console.log(e,'validateLogin error');
    Cookies.remove("auth");
    Cookies.remove("authPermission");
    return false;
    // navigate('/login', { replace: true });
  }
}

export const getAuthData = () => {
  try {
   // const a = Cookies.get("auth");
   const a = localStorage.getItem("auth");
    if (a != null) {
      const authData = JSON.parse(decryptFieldUsingAES(a));
     console.log(authData, ' authData' );
      return authData;

    }
    return null;

  } catch (e) {

    return null;

  }
}

export const buildMenuList = () => {
  try {

      // eslint-disable-next-line react-hooks/rules-of-hooks
     

    const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
    const encrypt =  localStorage.getItem("authPermission"); // Cookies.get("authPermission");
    if (encrypt != null) {
      const permissionList = JSON.parse(decryptFieldUsingAES(encrypt)).permission;
     // console.log(permissionList, ' permissionList menu');
      const menuGroup = permissionList.map((element) => element.menuGroup);
      const menuArea = new Set(menuGroup);
      const menuList = [];
      menuArea.forEach((item) => {
        const obj = {
          'area': item,
          'menu':
            permissionList.filter(x => x.menuGroup === item && x.IsMainMenu === true).map((menu) => ({
              'title': menu.MenuName,
              'path': `/${menu.Url}`,
              'icon': icon(menu.Icon),
              'area': item,
              'role': menu.RoleName,
              'roleId': menu.RoleId,
              'rolePermissionId': menu.RolePermissionId,
              'menuId': menu.MenuId,
              'isMainMenu': menu.IsMainMenu,
              'isAllowViewRecord': menu.IsAllowViewRecord,
              'isAllowNewRecord': menu.IsAllowNewRecord,
              'isAllowEditRecord': menu.IsAllowEditRecord
            })),
          'child': permissionList.filter(x => x.menuGroup === item && x.IsMainMenu === false).map((menu) => ({
            'title': menu.MenuName,
            'path': `/${menu.Url}`,
            'icon': icon(menu.Icon),
            'area': item,
            'role': menu.RoleName,
            'roleId': menu.RoleId,
            'rolePermissionId': menu.RolePermissionId,
            'menuId': menu.MenuId,
            'isMainMenu': menu.IsMainMenu,
            'isAllowViewRecord': menu.IsAllowViewRecord,
            'isAllowNewRecord': menu.IsAllowNewRecord,
            'isAllowEditRecord': menu.IsAllowEditRecord
          }))
        }
        menuList.push(obj);
      })
     // console.log(menuList,'MenuList');
      return menuList;
    }
  } catch (e) {
   // alert(e);
    console.log(e, " buildMenuList error");
  }
  return [];

}

export const validatePermission =  () => {
  try{
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const location = useLocation();
    const url = window.location.pathname.split('/')[1];
   // const encrypt = Cookies.get("authPermission");
   const encrypt = localStorage.getItem("authPermission");
 // console.log(url,' validation url');
    if (encrypt != null) {
    //  console.log(JSON.parse(decryptFieldUsingAES(encrypt)),'JSON.parse(decryptFieldUsingAES(encrypt))');
      const permissionList = JSON.parse(decryptFieldUsingAES(encrypt)).permission;
     // console.log(permissionList,' menu permission');
      const index=permissionList.findIndex(x=>x.Url.toLowerCase()===url.toLowerCase());
     // console.log(index,' permission index');
      return !(index<0);
    }
  }catch(e)
  {
    console.log(e)
  }
  return false;
}

export const specificPermission =  () => {
  const acces={
    newRecord:false,
    editRecord:false,
    viewRecord:false
  };
  try{
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();
    const url = location.pathname.split('/')[1];
    const encrypt = localStorage.getItem("authPermission");
  
    if (encrypt != null) {
      const permissionList = JSON.parse(decryptFieldUsingAES(encrypt)).permission;
      const obj=permissionList.filter(x=>x.Url===url);
     if(obj.length>0)
     {
      acces.newRecord=obj[0].IsAllowNewRecord;
      acces.editRecord=obj[0].IsAllowEditRecord;
      acces.viewRecord=obj[0].IsAllowViewRecord;
     }
      return acces;
    }
  }catch(e)
  {
    console.log(e)
  }
   return acces;
}

