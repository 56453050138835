import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  DialogTitle,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  styled,
  tableCellClasses,
  Card,
  ButtonGroup,
  Grid,
  TextField
} from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(empId, empName, time) {
  return { empId, empName, time };
}

const rows = [createData('E0607', 'JOSEPH JAMES', 10), createData('E1009', 'ABISH ANTONY XAVIEM', 10)];

const PauseWorkDialogBox = ({ open, onClose, employees, onConfirm }) => {
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [comment, setComment] = useState('');

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  useEffect(() => {
    if (open) {
      setComment('');
    }
  }, [open]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Complete Work</DialogTitle>
      <DialogContent>
        <Card>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>EMP ID</StyledTableCell>
                  <StyledTableCell align="left">EMP Name</StyledTableCell>
                  <StyledTableCell align="center">Time</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.empId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell component="th" scope="row">
                      {row.empId}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.empName}</StyledTableCell>
                    <StyledTableCell align="center">{row.time}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <TextField id="outlined-basic" label="Comment" variant="outlined" size='small' fullWidth sx={{mt:2}} onChange={handleCommentChange} />
      </DialogContent>
      <DialogActions sx={{pr:3, pb:2}}>
        <Button onClick={handleClose} variant="outlined" style={{ borderColor: 'black', color: 'black' }} size="small">
          Cancel
        </Button>        
        <Button variant="contained" color="success" size="small" onClick={handleConfirm} disabled={comment.trim() === ''}> Complete Work</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PauseWorkDialogBox;
